import { useCallback, useMemo } from 'react';
import { MenuConfig } from '.';
import { useCurrentFeature } from 'hooks/components/auth/useCurrentUserFeature';
import { useCurrentPermission } from 'pro/hooks/useCurrentPermissionPro';
import { useCurrentRole } from 'hooks/components/auth';

export const useAuthorizedMenu = (menu: MenuConfig[]) => {
  const [currentUserFeatures, checkHasFeatures] = useCurrentFeature();
  const [currentUserPermissions, checkHasPermission] = useCurrentPermission();
  const currentRole = useCurrentRole();

  const canDisplay = useCallback(
    (item: Omit<MenuConfig, 'subMenu' | 'icon'>) => {
      if (!currentUserFeatures?.length && !currentUserPermissions?.length) return false;

      const { features, roles, permissions } = item;

      const hasValidRole = roles
        ? roles.includes(currentRole!)
        : true;

      if (!features && !permissions) return hasValidRole;

      const isValidFeature = features?.length ? checkHasFeatures(features) : true;

      const isValidPermission = permissions?.length ? checkHasPermission(permissions) : true;

      return isValidFeature && isValidPermission && hasValidRole;
    },
    [checkHasFeatures, checkHasPermission, currentRole, currentUserFeatures?.length, currentUserPermissions?.length]
  );

  return useMemo(() => {
    if (!menu.length) return [];
    const result: MenuConfig[] = [];

    for (let index = 0; index < menu.length; index++) {
      let menuItem = menu[index];

      if (canDisplay(menuItem)) {
        if (menuItem.subMenu?.length) {
          menuItem = {
            ...menuItem,
            subMenu: menuItem.subMenu.filter((item) => canDisplay(item)),
          };
        }
        result.push(menuItem);
      }
    }

    return result;
  }, [menu, canDisplay]);
};
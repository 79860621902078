import { ManagerSerializer, WorkerSerializer } from '../utils/serializer/workforce';
import { config } from '../config/config'
import { getPermission } from '../config/user';
import axios from 'axios';
import { authStorage } from 'configs/browser-storage';
import clevertap from 'clevertap-web-sdk';
import { flattenJob, readyForCT } from 'pro/utils/functions';
// show all managers
export const ShowAllManagers = async (filter: string = '') => {

  try {
    let params = ''
    if (filter !== '') {
      params = `&status=${filter}`
    }
    const response = await axios.get(`${config.API_URL}/workforce/managers/?expand=role,locations${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(27)?.action
        'X-Action': 'list-workforce-manager'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// create new manager

// MSG-email
// MSG-whatsapp
// MSG-push
// MSG-sms
export const SubmitNewManager = async (manager: object) => {

  const form = new FormData();
  const newManager: any = ManagerSerializer(manager);
  const allowedCreateFields = [
    "avatar",
    "first_name",
    "middle_name",
    "last_name",
    "role_id",
    'company',
    "contact_number",
    "email",
  ]
  Object.keys(newManager).map((manager: any) => {
    if (manager === 'locations') {
      newManager[manager].map((location: any) => {
        form.append(manager, location)
        return manager
      })
    } else if (manager === 'role') {
      form.append(manager, newManager[manager].id)
    } else {
      if (allowedCreateFields.includes(manager)) {
        form.append(manager, newManager[manager])
      }
    }
    return manager;
  });

  try {
    const response = await axios.post(`${config.API_URL}/workforce/managers/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(28)?.action
        'X-Action': 'create-workforce-manager'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Manager (Web)", readyForCT(flattenedData));;
    return response.data;
  } catch (err) {
    throw err;
  }

};

// get selected manager
export const GetManager = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/workforce/managers/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(27)?.action
        'X-Action': 'list-workforce-manager'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get manager types
export const GetManagerTypes = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/workforce/managers/types/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(31)?.action
        'X-Action': 'get-manager-types'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update manager selected
export const UpdateManager = async (manager: object) => {

  const form = new FormData();
  const newManager: any = ManagerSerializer(manager);

  const allowedUpdateFields = [
    "avatar",
    "first_name",
    "middle_name",
    "last_name",
    "role_id",
    "contact_number",
    "email",
  ]

  Object.keys(newManager).map((manager: any) => {
    if (manager === 'locations') {
      newManager[manager].map((outlet: any) => {
        form.append(manager, outlet)
        return manager
      })
    } else {
      if (allowedUpdateFields.includes(manager)) {
        form.append(manager, newManager[manager])
      }
    }
    return manager;
  });

  try {
    const response = await axios.patch(`${config.API_URL}/workforce/managers/${newManager?.id}/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(29)?.action
        'X-Action': 'update-workforce-manager'
      }
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Manager (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};

// delete manager selected
export const DeleteManager = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/workforce/managers/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(30)?.action
        'X-Action': 'delete-workforce-manager'
      }
    });
    clevertap.event.push("Delete Manager (Web)", { id: id });
    return response.data;
  } catch (err) {
    throw err;
  }

};

// download template csv for manager
export const DownloadTemplateManager = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/workforce/managers/download-csv-template/`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(33)?.action
        'X-Action': 'download-workforce-manager'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// upload manager csv
export const UploadCsvManager = async (csv: File) => {

  const newCsv = new FormData();
  newCsv.append('file', csv);

  try {
    const response = await axios.post(`${config.API_URL}/workforce/managers/upload-csv/`, newCsv, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(32)?.action
        'X-Action': 'upload-workforce-manager'
      }
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Managers [CSV] (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};

// download selected for manager
export const DownloadListManager = async (list: any) => {

  const newList = structuredClone(list);

  try {
    const response = await axios.get(`${config.API_URL}/workforce/managers/download-csv/?manager_ids=${String(newList).toString()}`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(33)?.action
        'X-Action': 'download-workforce-manager'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// show all workers
export const getNextPrev = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': getPermission(34)?.action
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const ShowAllWorkers = async (status: string = '', rate: string = '', limit = true) => {

  try {
    let statParams = ''
    let rateParams = ''
    if (status !== '') {
      statParams = `&status=${status}`
    }
    if (rate !== '') {
      rateParams = `&worker_type=${rate}`
    }
    const response = await axios.get(`${config.API_URL}/workforce/workers/?expand=locations,identity_documents${statParams}${rateParams}${limit ? "&limit=500" : ""}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(34)?.action
        'X-Action': 'list-workforce-worker'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// create new worker
export const SubmitNewWorker = async (worker: object) => {

  const form = new FormData();
  const newWorker: any = WorkerSerializer(worker);
  Object.keys(newWorker).map((user: any) => {
    form.append(user, newWorker[user])
    return user;
  });

  try {
    const response = await axios.post(`${config.API_URL}/workforce/workers/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': getPermission(35)?.action
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Worker (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get selected worker
export const GetWorker = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/workforce/workers/${id}/?expand=locations,banks__bank,contacts,identity_documents`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(36)?.action
        'X-Action': 'retrieve-workforce-worker'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update worker selected
export const UpdateWorker = async (worker: object) => {

  const form = new FormData();
  const newWorker: any = WorkerSerializer(worker);
  Object.keys(newWorker).map((user: any) => {
    form.append(user, newWorker[user])
    return user;
  });

  try {
    const response = await axios.put(`${config.API_URL}/workforce/workers/${newWorker?.id}/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(37)?.action
        'X-Action': 'update-workforce-worker'
      }
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Worker (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};

// delete worker selected
export const DeleteWorker = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/workforce/workers/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(38)?.action
        'X-Action': 'delete-workforce-worker'
      }
    });
    clevertap.event.push("Delete Worker (Web)", { id: id });
    return response.data;
  } catch (err) {
    throw err;
  }

};

// download template csv for worker
export const DownloadTemplateWorker = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/workforce/workers/download-csv-template/`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(40)?.action
        'X-Action': 'download-workforce-worker'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// upload worker csv
export const UploadCsvWorker = async (csv: File) => {

  const newCsv = new FormData();
  newCsv.append('file', csv);

  try {
    const response = await axios.post(`${config.API_URL}/workforce/workers/upload-csv/`, newCsv, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(39)?.action
        'X-Action': 'upload-workforce-worker'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Workers [CSV] (Web)", readyForCT(flattenedData));;
    return response.data;
  } catch (err) {
    throw err;
  }

};

// download selected for worker
export const DownloadListWorker = async (list: any) => {

  const newList = structuredClone(list);

  try {
    const response = await axios.get(`${config.API_URL}/workforce/workers/download-csv/?worker_ids=${String(newList).toString()}`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(40)?.action
        'X-Action': 'download-workforce-worker'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const StatusManager = async (status: object, id: number) => {

  try {
    const response = await axios.patch(`${config.API_URL}/workforce/managers/${id}/`, status, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(29)?.action
        'X-Action': 'update-workforce-manager'
      }
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Manager Status (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const StatusWorker = async (status: object, id: number) => {

  try {
    const response = await axios.patch(`${config.API_URL}/workforce/workers/${id}/`, status, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(29)?.action
        'X-Action': 'update-workforce-worker'
      }
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Worker Status (Web)", readyForCT(flattenedData));;

    return response.data;
  } catch (err) {
    throw err;
  }

};
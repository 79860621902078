import { FC } from "react";
import { Box, Text, VStack, HStack, Avatar, Divider } from "@chakra-ui/react";
import ExpandableDetails from "pro/components/ExpandableDetails";

interface ClockInFormProps {
  data: any;
}

const ClockInForm: FC<ClockInFormProps> = ({data}) => {
  return (
    <VStack
          w="full"
          spacing={4}
          // p={8}
          rounded="xl"
          shadow="md"
          bg="white"
          align="stretch"
        >
      <VStack w="full">
        <Divider borderWidth="2px" borderColor="gray.300" borderRadius="lg" marginTop={4} marginBottom={4}/>
        <HStack w="full" spacing={2} justify="space-between" background="#F8F9FC" rounded="xl" p={4}>
          <VStack flex={1} justifyContent="center" alignItems="center">
            <Text fontSize={12} textAlign="center">Date</Text>
            <Text fontSize={12} textAlign="center">{data?.clock_in_date}</Text>
          </VStack>

          <HStack rounded="full" background="transparent" borderWidth={1} borderColor="black" flex={1} justifyContent="center" alignItems="center">
            <Text>{data?.clock_in_period}</Text>
          </HStack>

          <VStack flex={1} justifyContent="center" alignItems="center">
            <Text fontWeight="bold" color="#6BC64A" textAlign="center" fontSize={14}>Clock In</Text>
            <Text textAlign="center" fontSize={20}>{data?.clock_in_time}</Text>
          </VStack>

          <VStack flex={1} justifyContent="center" alignItems="center">
            <Text fontWeight="bold" color="#30B6FF" textAlign="center" fontSize={14}>Clock Out</Text>
            <Text textAlign="center" fontSize={20}>{data?.clock_out_time}</Text>
          </VStack>

          <VStack flex={1} justifyContent="center" alignItems="center">
            <Text fontWeight="bold" color="#FF9240" textAlign="center" fontSize={14}>Break Time</Text>
            <Text textAlign="center" fontSize={20}>{data?.break_time}</Text>
          </VStack>
        </HStack>

        <VStack w="full" borderWidth={1} borderColor="#EDF0F7" alignContent="start" rounded="lg" p={4}>
          <ExpandableDetails title={'Job Title'} detail={data?.job_title} />
          <ExpandableDetails title={'Job Description'} detail={data?.job_description} />
          <ExpandableDetails title={'Special Instructions'} detail={data?.special_instructions} />
        </VStack>
      {/* </>
        )}   */}
      </VStack>
      {/* {openDetails && ( */}
        <HStack w="full" justifyContent="space-between" backgroundColor="#FF9240" p={4}>
          <Text color="white" fontWeight="bold">Estimated Earnings</Text>
          <Text color="white" fontWeight="bold">{data?.estimated_earnings}</Text>
        </HStack>
      {/* )} */}
    </VStack>
  );
}

export default ClockInForm;
import { getCompanyId } from "../../config/user";

export const OutletListSerializer = (value: any) => {
  let outlet: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < outlet.length; i++) {
    let managerArr: Array<any> = []
    if (outlet[i].users.length > 0) {
      outlet[i].users.map((user: any) => managerArr.push(user.display_name))
    }
    let obj: object = {
      id: outlet[i].id,
      name: {
        title: outlet[i].name,
        image: outlet[i].logo,
      },
      outlet_id: outlet[i].id,
      users: String(managerArr).replace(',', ', '),
      workforce: outlet[i].no_of_workers,
      is_enable: outlet[i].is_enable ? 'Enable' : 'Disable',
    }
    arr.push(obj);
  }

  return arr;
}

export const OutletRegionSerializer = (value: any) => {
  let region: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < region.length; i++) {
    let obj: object = {
      id: region[i].id,
      value: region[i].name,
      label: region[i].name,
      cities: region[i].cities,
    }
    arr.push(obj);
  }

  return arr;
}

export const OutletCitySerializer = (value: any) => {
  let city: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < city.length; i++) {
    let obj: object = {
      region: city[i].region,
      value: city[i].name,
      label: city[i].name,
    }
    arr.push(obj);
  }

  return arr;
}

export const OutletSerializer = (value: any) => {
  let outlet: any = structuredClone(value)
  if (typeof outlet.logo === 'string' || !outlet.logo) {
    delete outlet.logo
  }
  outlet.company = getCompanyId()
  delete outlet.deleted_at
  delete outlet.updated_by
  delete outlet.created_by

  return outlet;
}

export const OutletOptionSerializer = (value: any) => {
  let outlet: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < outlet.length; i++) {
    let obj: object = {
      value: outlet[i].id,
      label: outlet[i].name,
      // content: outlet[i]
    }
    arr.push(obj);
  }

  return arr;
}
import { FC } from 'react';
import Select from 'react-select';

interface OptionSelectProp {
  value: any;
  onChange: Function;
  edit: boolean;
  template: boolean;
  options: any;
  placeholder: string;
  multi: boolean;
  errors?: any;
}

const OptionSelect: FC<OptionSelectProp> = ({
  value,
  onChange,
  edit,
  template,
  options,
  placeholder,
  multi,
  errors,
}) => {
  return (
    <div className="flex flex-col w-full space-y-1">
      <div
        className={`flex flex-col w-full border-[1px] ease-in duration-150 rounded-md border-vGrayWhite shadow-none lg:shadow-sm focus-within:border-vOrange focus-within:border-[1px] ${(!edit || template) && 'bg-vGrayWhite pointer-events-none'}`}
      >
        <Select
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={(e: any) => onChange(e)}
          isMulti={multi}
        />
      </div>
      {errors && <p className="text-red-500 text-xs">{errors}</p>}
    </div>
  );
};

export default OptionSelect;

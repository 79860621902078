import { PATH_NAME } from 'configs';
// import { GuestRouteGuard, RolePermissionRouteGuard } from 'guards';
// import concat from 'lodash/concat';
import { FC, lazy } from 'react';

import { Role } from 'models/user';

// import { InternalPermission, PartnerPermission, SystemPermission } from 'types';

// import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';

import Error404 from 'pages/Error404';
import { SystemPermissions, Permissions } from 'pro/types/permissions';
import FeatureRouteGuard from 'pro/guard/FeatureRouteGuard';
// import { userPermission } from 'pro/config/user';

export interface RouteConfig {
  path: string;
  roles?: Role[];
  permissions?: SystemPermissions[];
  element?: FC;
  roleComponents?: { [key in Role]?: FC };
  title?: string;
  breakLineTitle?: [string, string];
  metaData?: { title: string };
  guard?: FC<any>;
  layout?: FC;
  disabled?: boolean;
  type?: 'all' | 'internal' | 'partner' | 'superHq';
  defaultScrollOnTop?: boolean;
  excludeMainLayout?: boolean;
}

const Demo = lazy(() => {
  if (process.env.NODE_ENV === 'development') {
    return import('pages/Demo');
  }
  return import('pages/Error404');
});

const Login = lazy(() => import('pages/Login'));
const FirstLoginAuth = lazy(() => import('pro/pages/FirstLoginAuth'));
const AuthSuccessPage = lazy(() => import('pro/pages/AuthSuccessPage'));
// const SystemLocations = lazy(() => import('pages/SystemLocations'));
// const Applicant = lazy(() => import('pages/Applicant'));
// const ApplicantNew = lazy(() => import('pages/ApplicantNew'));
// const ApplicantEdit = lazy(() => import('pages/ApplicantEdit'));
// const BillingRecord = lazy(() => import('pages/BillingRecord'));
// const PaymentSummary = lazy(() => import('pages/PaymentSummary'));
// const PaymentProcessed = lazy(() => import('pages/PaymentProcessed'));
// const PaymentFuture = lazy(() => import('pages/PaymentFuture'));
// const ReportList = lazy(() => import('pages/Report'));
// const SystemManager = lazy(() => import('pages/SystemManager'));
// const SystemManagerEdit = lazy(() => import('pages/SystemManagerEdit'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
// const UserRole = lazy(() => import('pages/UserRole'));
// const Permission = lazy(() => import('pages/Permission'));
// const NotificationConfiguration = lazy(() => import('pages/NotificationConfiguration'));
// const OtherConfiguration = lazy(() => import('pages/OtherConfiguration'));
// const AppConfiguration = lazy(() => import('pages/AppConfiguration'));
const Dashboard = lazy(() => import('pro/pages/Dashboard'));
const Schedule = lazy(() => import('pro/pages/Schedule'));
const Jobs = lazy(() => import('pro/pages/Jobs'));

const Approval = lazy(() => import('pro/pages/SwapRequest'));
const JobTemplate = lazy(() => import('pro/pages/JobTemplate'));
const PaymentRules = lazy(() => import('pro/pages/PaymentRules'));
const WorkforceManager = lazy(() => import('pro/pages/WorkforceManager'));
const WorkforceWorker = lazy(() => import('pro/pages/WorkforceWorker'));
const Outlet = lazy(() => import('pro/pages/Outlet'));
const Payroll = lazy(() => import('pro/pages/Payroll'));
const AccountSettings = lazy(() => import('pro/pages/AccountSettings'));
// const BillingSummary = lazy(() => import('pro/pages/BillingSummary'));
// const PaymentSettings = lazy(() => import('pro/pages/PaymentSettings'));
const Subscription = lazy(() => import('pro/pages/Subscription'));
const Company = lazy(() => import('pages/Company'));
const Companies = lazy(() => import('pages/Companies'));
const LeaveTracker = lazy(() => import('pro/pages/LeaveTracker'));
const ReportJob = lazy(() => import('pro/pages/ReportJob'));
const ReportOutlet = lazy(() => import('pro/pages/ReportOutlet'));
const ReportPayroll = lazy(() => import('pro/pages/ReportPayroll'));

export const publicRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.ERROR_404,
    element: Error404,
    metaData: { title: 'title.404PageNotFound' },
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.LOGIN,
    element: Login,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.FORGOT_PASSWORD,
    element: ForgotPassword,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.RESET_PASSWORD,
    element: ResetPassword,
    excludeMainLayout: true,
  },
  // {
  //   path: PATH_NAME.VERIFY_EMAIL,
  //   //  guard: GuestRouteGuard,
  //   element: VerifyHqEmail,
  // },
  // {
  //   path: PATH_NAME.VERIFY_EMAIL,
  //   //  guard: GuestRouteGuard,
  //   element: VerifyHqEmail,
  // },
];

export const portalRoutes: RouteConfig[] = [
  {
    path: '/demo',
    element: Demo,
    metaData: { title: 'Demo' },
    title: 'Demo',
  },
  {
    path: '*',
    element: Error404,
    metaData: { title: '404 Page Not Found' },
  },
  {
    path: PATH_NAME.DASHBOARD,
    element: Dashboard,
    guard: FeatureRouteGuard,
    permissions: [Permissions.DASHBOARD],
    type: 'internal',
  },
  {
    path: PATH_NAME.SCHEDULE,
    element: Schedule,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_ALL_JOBS],
  },
  {
    path: PATH_NAME.JOBS,
    element: Jobs,
    guard: FeatureRouteGuard,
    type: 'internal',
    roles: [Role.Admin],
    permissions: [Permissions.LIST_ALL_JOBS],
  },
  {
    path: PATH_NAME.SWAP_REQUEST,
    element: Approval,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_SWAP_REQUEST],
  },
  {
    path: PATH_NAME.LEAVE_TRACKER,
    element: LeaveTracker,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LEAVES_LIST],
  },
  {
    path: PATH_NAME.JOB_TEMPLATE,
    element: JobTemplate,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_JOB_TEMPLATE],
  },
  {
    path: PATH_NAME.PAYMENT_RULES,
    element: PaymentRules,
    guard: FeatureRouteGuard,
    type: 'internal',
    // permissions: [
    //   Permissions.CREATE_PAYMENT_RULE,
    //   Permissions.UPDATE_PAYMENT_RULE,
    //   Permissions.DELETE_PAYMENT_RULE
    // ],
  },
  {
    path: PATH_NAME.WORKFORCE_MANAGER,
    element: WorkforceManager,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_WORKFORCE_MANAGER],
  },
  {
    path: PATH_NAME.WORKFORCE_WORKER,
    element: WorkforceWorker,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_WORKFORCE_WORKER],
  },
  {
    path: PATH_NAME.OUTLET,
    element: Outlet,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_LOCATIONS],
  },
  {
    path: PATH_NAME.PAYROLL,
    element: Payroll,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_PAYROLL],
  },
  {
    path: PATH_NAME.ACCOUNT_SETTINGS,
    element: AccountSettings,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_ROLES],
  },
  // {
  //   path: PATH_NAME.BILLING_SUMMARY,
  //   element: BillingSummary,
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.PAYMENT_SETTINGS,
  //   element: PaymentSettings,
  //   type: 'internal',
  // },
  {
    path: PATH_NAME.SUBSCRIPTION,
    element: Subscription,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.VIEW_COMPANY_DETAIL],
  },
  {
    path: PATH_NAME.COMPANIES,
    element: Companies,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_COMPANIES],
  },
  {
    path: PATH_NAME.COMPANY,
    element: Company,
    guard: FeatureRouteGuard,
    type: 'partner',
    permissions: [Permissions.VIEW_COMPANY_DETAIL],
  },
  {
    path: `${PATH_NAME.COMPANIES}/:id`,
    element: Companies,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_COMPANIES, Permissions.VIEW_COMPANY_DETAIL],
  },
  {
    path: PATH_NAME.REPORT_PAYROLL,
    element: ReportPayroll,
    guard: FeatureRouteGuard,
    type: 'internal',
  },
  {
    path: PATH_NAME.REPORT_JOB,
    element: ReportJob,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_ALL_JOBS],
  },
  {
    path: PATH_NAME.REPORT_OUTLET,
    element: ReportOutlet,
    guard: FeatureRouteGuard,
    type: 'internal',
    permissions: [Permissions.LIST_LOCATIONS],
  },
  {
    path: PATH_NAME.AUTH_OTP,
    element: FirstLoginAuth,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.SUCCESSFUL_LOGIN,
    element: AuthSuccessPage,
    excludeMainLayout: true,
  },
];

export const privateRoutes: RouteConfig[] = portalRoutes;

import { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Button,
  Image,
  Avatar,
  IconButton,
  Input,
} from '@chakra-ui/react';
import {
  ClockInConfirmLocation,
  ClockInQr,
  ClockOutConfirmLocation,
  ClockOutQr,
  OtpClockIn,
  OtpClockOut,
} from 'pro/api/jobs';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6';
import { FailModal, SuccessModal } from './Modal';
import ClockInForm from 'pro/forms/schedule/ClockInForm';
import ClockOutForm from 'pro/forms/schedule/ClockOutForm';
import { ClockOutInterface } from 'pro/utils/interface/jobs';
import UserJobDetail from 'pro/forms/schedule/UserJobDetail';
import UserJobCost from 'pro/forms/schedule/UserJobCost';

type ClockInOutType = 'location' | 'normal';

interface ClockInOutItemProps {
  index: number;
  type: ClockInOutType;
  data: any;
  refresh: Function;
}

const ClockInOutItem: FC<ClockInOutItemProps> = (props) => {
  const bgColors = ['#EDF0F7', '#FFECD4', '#DFF1FF'];
  const titleColors = ['#A5B2CD', '#FF9240', '#30B6FF'];
  const [showQR, setShowQR] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>('');
  const [openFailQrCode, setOpenFailQrCode] = useState<boolean>(false);
  const [openFailOtp, setOpenFailOtp] = useState<boolean>(false);
  const [openSuccessOtp, setOpenSuccessOtp] = useState<boolean>(false);
  const [openSuccessClockOutOtp, setOpenSuccessClockOutOtp] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [clockout, setClockout] = useState<any>(structuredClone(ClockOutInterface));
  const [otpMode, setOtpMode] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<string>('');

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);

    const utcDate = new Date(date.getTime() + 8 * 60 * 60 * 1000);

    return utcDate.toISOString().slice(0, 19).replace('T', ' ');
  };

  const convertToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const isFirstRender = useRef(true);

  const handleClockInOut = () => {
    setShowQR(true);
    clockQr();
  };

  const confirmLocationClockInOut = () => {
    if (props.data?.status === 'Not Started') {
      ClockInConfirmLocation(props.data?.shift_id, props.data?.user_id, props.data?.id)
        .then((res: any) => {
          if (res?.success) {
            props.refresh();
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
    if (
      props.data?.status === 'Clocked In' ||
      props.data?.status === 'Ready to Clock Out' ||
      props.data?.status === 'Needs Clock Out'
    ) {
      ClockOutConfirmLocation(
        props.data?.shift_id,
        props.data
          ?.user_id /*, otpMode, formattedStartTime, formattedEndTime, formattedBreakTime, clockout.rating, clockout.feedback*/,
        props.data?.id,
      )
        .then((res: any) => {
          if (res?.success) {
            props.refresh();
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
  };

  const toggleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const toggleMode = () => {
    setOtpMode(!otpMode);
  };

  const clockQr = () => {
    if (props.data?.status === 'Not Started') {
      ClockInQr(props.data?.shift_id, props.data?.user_id, otpMode, props.data?.id)
        .then((res: any) => {
          if (res?.success) {
            setQrCode(res?.data.qrcode_url);
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
    if (
      props.data?.status === 'Clocked In' ||
      props.data?.status === 'Ready to Clock Out' ||
      props.data?.status === 'Needs Clock Out'
    ) {
      const formattedStartTime = formatDateTime(clockout.start_time);
      const formattedEndTime = formatDateTime(clockout.end_time);
      const formattedBreakTime = convertToMinutes(clockout.break_time);

      ClockOutQr(
        props.data?.shift_id,
        props.data?.user_id,
        otpMode,
        formattedStartTime,
        formattedEndTime,
        formattedBreakTime,
        clockout.rating,
        clockout.feedback,
        clockout.deduction,
        clockout.addition,
        props.data?.id,
      )
        .then((res: any) => {
          if (res?.success) {
            setQrCode(res?.data.qrcode_url);
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      clockQr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpMode]);

  const handleSubmitOtp = () => {
    if (props.data?.status === 'Not Started') {
      OtpClockIn(otpValue, props.data?.shift_id, props.data.user_id)
        .then((res: any) => {
          setOpenSuccessOtp(true);
          props.refresh();
        })
        .catch(() => setOpenFailOtp(true));
    }
    if (
      props.data?.status === 'Clocked In' ||
      props.data?.status === 'Ready to Clock Out' ||
      props.data?.status === 'Needs Clock Out'
    ) {
      OtpClockOut(
        otpValue, 
        props.data?.shift_id, 
        props.data.user_id,
        clockout.addition,
        clockout.deduction
      )
        .then((res: any) => {
          setOpenSuccessClockOutOtp(true);
          props.refresh();
        })
        .catch(() => setOpenFailOtp(true));
    }
  };

  const backgroundColor =
    props.data?.status === 'Not Started'
      ? '#FF9240'
      : props.data?.status === 'Needs Clock Out'
        ? '#30B6FF'
        : props.data?.status === 'Completed'
          ? '#43CB12'
          : '#FF9240';

  return (
    <VStack w="full" spacing="10px" p="10px" align="stretch">
      <VStack
        w="full"
        rounded="xl"
        shadow="md"
        bg="white"
        align="stretch"
        paddingTop="10px"
        paddingLeft="10px"
        paddingRight="10px"
        paddingBottom="10px"
      >
        <VStack w="full">
          <HStack w="full" justify="space-between">
            <HStack>
              <Avatar
                boxSize="60px"
                color="white"
                bg={!props.data?.avatar ? 'tango.300' : 'transparent'}
                borderRadius="full"
                name={props.data?.full_name}
                src={props.data?.avatar}
              />
              <VStack alignItems="start">
                <Text fontWeight="bold">{props.data?.full_name}</Text>
                <Text color="#999999">{props.data?.job_title}</Text>
              </VStack>
            </HStack>
            <Box
              rounded="md"
              backgroundColor="#FF9240"
              p={2}
              onClick={toggleDetails}
              cursor="pointer"
            >
              <Text textColor="white">
                {props.data?.status !== 'Needs Clock Out' ? 'Clock In' : 'Clock Out'}
              </Text>
            </Box>
          </HStack>
        </VStack>

        {
          openDetails &&
            (props.data?.status !== 'Completed' ? (
              props.data?.status === 'Ready to Clock In' || props.data?.status === 'Not Started' ? (
                <ClockInForm data={props.data} />
              ) : (
                <UserJobDetail
                  job={props.data?.job}
                  shifts={props.data?.shift}
                  userStatus={
                    props.data?.status === 'Completed' ? 'Clocked Out' : props.data?.status
                  }
                  clockout={clockout}
                  setClockout={setClockout}
                  editable={openDetails}
                />
                // <ClockOutForm data={props.data} clockout={clockout} setClockout={setClockout} />
              )
            ) : null)
          // <div className="flex w-full pb-2">
          //   <button
          //     className={`flex w-full justify-center py-2 border-b-2 ease-in duration-150 hover:bg-vLightGray rounded-t-md ${!jobDetails ? 'text-vOrange border-vOrange font-bold' : 'text-vGray border-vGray'}`}
          //     onClick={() => isJobDetails(false)}
          //   >
          //     <p>Details</p>
          //   </button>
          //   <button
          //     className={`flex w-full justify-center py-2 border-b-2 ease-in duration-150 hover:bg-vLightGray rounded-t-md ${jobDetails ? 'text-vOrange border-vOrange font-bold' : 'text-vGray border-vGray'}`}
          //     onClick={() => isJobDetails(true)}
          //   >
          //     <p>Cost Breakdown</p>
          //   </button>
          // </div>
        }

        {openDetails && props.data?.status === 'Completed' && (
          <UserJobDetail
            job={props.data?.job}
            shifts={props.data?.shift}
            userStatus={props.data?.status === 'Completed' ? 'Clocked Out' : props.data?.status}
            clockout={clockout}
            setClockout={setClockout}
            editable={!openDetails}
          />
        )}
        {/* {openDetails && props.data?.status === 'Completed' && jobDetails && (
          <UserJobCost />
        )} */}
      </VStack>

      {openDetails && props.data?.status !== 'Completed' && (
        <>
          {showQR ? (
            <VStack w="full" spacing={4} p={8} rounded="lg" shadow="md" bg="white" align="center">
              <Text fontWeight="bold">
                {otpMode
                  ? props.data?.status !== 'Needs Clock Out'
                    ? 'Clock In OTP'
                    : 'Clock Out OTP'
                  : props.data?.status !== 'Needs Clock Out'
                    ? 'Clock In QR'
                    : 'Clock Out QR'}
              </Text>
              {otpMode ? (
                <VStack w="full" alignItems="end" gap={0}>
                  <Input
                    placeholder="Enter OTP"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    maxLength={6}
                  />
                  <Button onClick={clockQr} variant="link" color="#FF9240">
                    Resend Otp
                  </Button>
                </VStack>
              ) : (
                <Image src={qrCode} maxHeight={'150px'} maxWidth={'150px'} />
              )}
              <button
                className="bg-vLightOrange py-3 rounded-md text-vOrange w-full"
                onClick={handleSubmitOtp}
              >
                Confirm
              </button>
              <button
                className="bg-vOrange py-3 rounded-md text-vWhite w-full"
                onClick={toggleMode}
              >
                {otpMode ? 'Switch to QR' : 'Switch to OTP'}
              </button>
            </VStack>
          ) : (
            <button
              className={`py-3 rounded-md w-full ${props.data?.status !== 'Not Started' && props.data?.status !== 'Needs Clock Out' ? 'bg-gray-400 text-gray-200' : 'bg-vOrange text-vWhite'}`}
              disabled={
                props.data?.status !== 'Not Started' && props.data?.status !== 'Needs Clock Out'
              }
              onClick={
                props.data?.is_scan_user_location_qr ? confirmLocationClockInOut : handleClockInOut
              }
            >
              <p>
                {props.data?.is_scan_user_location_qr
                  ? 'Confirm'
                  : props.data?.status !== 'Needs Clock Out'
                    ? 'Clock In'
                    : 'Clock Out'}
              </p>
            </button>
          )}
        </>
      )}
      {openSuccessOtp && (
        <SuccessModal
          confirm={() => setOpenSuccessOtp((curr: boolean) => !curr)}
          title="Otp Clock In"
          message={`Otp Clock In Completed Successfully`}
        />
      )}
      {openSuccessClockOutOtp && (
        <SuccessModal
          confirm={() => setOpenSuccessClockOutOtp((curr: boolean) => !curr)}
          title="Otp Clock Out"
          message={`Otp Clock Out Completed Successfully`}
        />
      )}
      {openFailQrCode && (
        <FailModal
          confirm={() => setOpenFailQrCode((curr: boolean) => !curr)}
          cancel={() => setOpenFailQrCode((curr: boolean) => !curr)}
          title="Wait!"
          message="No upcoming shift"
        />
      )}
      {openFailOtp && (
        <FailModal
          confirm={() => setOpenFailOtp((curr: boolean) => !curr)}
          cancel={() => setOpenFailOtp((curr: boolean) => !curr)}
          title="Wait!"
          message="There is an issue with the otp. Please try again"
        />
      )}
    </VStack>
  );
};

export default ClockInOutItem;

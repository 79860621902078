import { ClockInOutFilterTemplate } from 'pro/utils/interface/attendance';
import { FC, useState } from 'react';
import { FaSliders } from 'react-icons/fa6';

interface ClockInOutFilterProp {
  paramsFilter: Function;
  outlets: Array<any>;
  listFilter: Function;
}

const ClockInOutFilter: FC<ClockInOutFilterProp> = ({ paramsFilter, outlets, listFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<ClockInOutFilterTemplate>(
    structuredClone(ClockInOutFilterTemplate)
  );

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirm = () => {
    paramsFilter({
      qr_type: filter.qr_type === 'All' || filter.qr_type === '' ? '' : filter.qr_type,
      clock_type: filter.clock_type === 'All' || filter.clock_type === '' ? '' : filter.clock_type,
    });
    listFilter({
      outlet_id: filter.outlet_id,
    });
    setIsOpen(false);
  };

  const handleReset = () => {
    setFilter(structuredClone(ClockInOutFilterTemplate));
  };

  return (
    <div className="relative">
      <button
        onClick={toggleModal}
        className="hidden lg:flex py-4 px-4 align-center justify-center bg-vWhite rounded-md shadow-md mr-2 w-full h-full"
      >
        <FaSliders />
      </button>

      <button
        onClick={toggleModal}
        className="flex lg:hidden px-4 align-center justify-center border-2 text-black font-medium bg-vWhite border-vGrayWhite rounded-md w-full h-[50px]"
      >
        Filter
      </button>

      <div
        className={`fixed inset-0 bg-black bg-opacity-40 transition-opacity duration-300 ${isOpen ? 'opacity-100 z-20' : 'opacity-0 -z-20'}`}
        onClick={toggleModal}
      />

      <div
        className={`z-30 absolute top-full mt-2 w-full lg:w-[400px] bg-white shadow-lg rounded-md p-4 transition-transform transform-gpu ease-in-out duration-300 origin-top ${
          isOpen ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0 pointer-events-none'
        }`}
      >
        <div className="flex flex-col space-y-4">
          <p className="text-base text-black font-bold">Search Filter:</p>

          <div>
            <p className="font-medium">QR Type</p>
            <div className="flex flex-col space-y-2">
              {['All', 'Location QR', 'Job QR'].map((qrType) => (
                <label key={qrType} className="flex items-center">
                  <input
                    type="radio"
                    name="qr_type"  // Unique name for QR Type
                    value={qrType}
                    checked={filter.qr_type === qrType}
                    onChange={(e) => setFilter((prev) => ({ ...prev, qr_type: e.target.value }))}
                    className="mr-2"
                  />
                  {qrType}
                </label>
              ))}
            </div>
          </div>

          <div>
            <p className="font-medium">Clock Status</p>
            <div className="flex flex-col space-y-2">
              {['All', 'Clock In', 'Clock Out'].map((clockStatus) => (
                <label key={clockStatus} className="flex items-center">
                  <input
                    type="radio"
                    name="clock_type"  // Unique name for Clock Status
                    value={clockStatus}
                    checked={filter.clock_type === clockStatus}
                    onChange={(e) => setFilter((prev) => ({ ...prev, clock_type: e.target.value }))}
                    className="mr-2"
                  />
                  {clockStatus}
                </label>
              ))}
            </div>
          </div>

          {outlets.length > 0 && (
            <div>
              <p className="font-medium">Outlets</p>
              <div className="flex flex-col space-y-2 max-h-60 overflow-auto">
                {outlets.map((o: any) => (
                  <label key={o.id} className="flex items-center">
                    <input
                      type="radio"
                      name="outlet"
                      value={o.name}
                      checked={filter.outlet_id === o.id}
                      onChange={(e) => setFilter((prev) => ({ ...prev, outlet_id: o.id }))}
                      className="mr-2"
                    />
                    {o.name}
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-col lg:flex-row justify-between gap-2">
            <button
              onClick={handleConfirm}
              className="bg-vOrange text-white px-4 py-2 rounded-md shadow-md w-full"
            >
              Confirm
            </button>
            <button
              onClick={handleReset}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md shadow-md w-full"
            >
              Reset Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClockInOutFilter;

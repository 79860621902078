import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getFirstLocation, getLocationOptions, getLocationSummary } from './action';
import { LocationState } from './type';

const initialState: LocationState = {
  locations: [],
  summary: {
    total: 0,
  },
  options: null,
  firstLocation: null,
  fetchFirstLocationStatus: null,
  fetchSummaryStatus: null,
  fetchOption: null,
};

const { actions, reducer: locationReducer } = createSlice({
  name: 'location',
  initialState,
  reducers: {
    updateLocations(state, action: PayloadAction<any>) {
      state.locations = action.payload;
    },
    addLocation(state, action: PayloadAction<any>) {
      state.locations.unshift(action.payload);
    },
    updateSummary(state, action: PayloadAction<Omit<LocationState['summary'], 'init'>>) {
      const { payload } = action;

      state.summary.total = payload.total;
    },
    updateOption(state, action: PayloadAction<LocationState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFirstLocation.fulfilled, (state, action) => {
      if (action.payload) {
        state.firstLocation = action.payload;
      }
      state.fetchFirstLocationStatus = action.meta.requestStatus;
    });
    builder.addCase(getFirstLocation.pending, (state, action) => {
      state.fetchFirstLocationStatus = action.meta.requestStatus;
    });
    builder.addCase(getFirstLocation.rejected, (state, action) => {
      state.fetchFirstLocationStatus = action.meta.requestStatus;
    });

    builder.addCase(getLocationSummary.fulfilled, (state, action) => {
      state.summary.total = action.payload;
      state.fetchSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationSummary.pending, (state, action) => {
      state.fetchSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationSummary.rejected, (state, action) => {
      state.fetchSummaryStatus = action.meta.requestStatus;
    });

    builder.addCase(getLocationOptions.fulfilled, (state, action) => {
      state.options = action.payload;
      state.fetchOption = action.meta.requestStatus;
    });
    builder.addCase(getLocationOptions.pending, (state, action) => {
      state.fetchOption = action.meta.requestStatus;
    });
    builder.addCase(getLocationOptions.rejected, (state, action) => {
      state.fetchOption = action.meta.requestStatus;
    });
  },
});

const locationActions = { ...actions, getFirstLocation, getLocationSummary, getLocationOptions };
export { locationReducer, locationActions };
export * from './type';
export * from './selector';

import { FC, useEffect, useState } from 'react';
import { FaSpinner, FaXmark } from 'react-icons/fa6';
import ManagerForm from './ManagerForm';
import { ManagerInterface } from '../../utils/interface/workforce';
import { SubmitNewManager } from '../../api/workforce';
import { FailModal, SuccessModal } from '../../components/Modal';
import SidePop from 'pro/components/SidePop';
import IconButton from 'pro/components/IconButton';
import Button from 'pro/components/Button';
import { currentRegionsSelector, currentManagersSelector } from 'store/modules/auth/selector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/root-reducer';
import { keptQueryActions } from 'store/modules/kept-query';
import { managerActions } from 'store/modules/manager';

interface CreateManagerProp {
  visible: boolean;
  onClose: () => void;
}

export const requiredFields = [
  'email',
  'contact_number',
  'role',
  'first_name',
  'last_name',
  'locations',
];

const CreateManager: FC<CreateManagerProp> = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const [manager, setManager] = useState<any>(structuredClone(ManagerInterface));
  const [openFail, setOpenFail] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(visible);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [apiError, setApiError] = useState<any>('');
  const stored_managers = useSelector(currentManagersSelector);
  useEffect(() => {
    if (visible) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), 300); // Delay to match the animation duration
    }
  }, [visible]);

  // validate input value if none or 0
  // const validate = () => {
  //   let valid: boolean = false;
  //   if (
  //     (manager?.first_name &&
  //       manager?.last_name &&
  //       manager?.middle_name &&
  //       manager?.contact_number &&
  //       manager?.email) !== '' &&
  //     (manager?.role) !== 0 &&
  //     (manager?.locations.length) > 0
  //   ) {
  //     valid = true;
  //   }
  //   return valid;
  // };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const validate = () => {
    let valid = true;
    const newErrors: Record<string, string> = {};

    requiredFields.forEach((field) => {
      if (!manager[field] || (Array.isArray(manager[field]) && manager[field].length === 0)) {
        valid = false;
        newErrors[field] = `${capitalizeWords(field.replace('_', ' '))} is required.`;
      }
      if (field === 'contact_number' && (manager[field].length < 9 || manager[field].length > 10)) {
        valid = false;
        newErrors[field] = `${capitalizeWords(field.replace('_', ' '))} must be a 9 to 10 digit number.`;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  // submit created manager
  const handleSubmit = async () => {
    try {
      setSaving(true);
      const newManager = await SubmitNewManager(manager);
      setOpenSuccess(true);
      setSaving(false);
      dispatch(managerActions.addNewManager(newManager));
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorDetails = error.response.data.detail;
        if (typeof errorDetails === 'string') {
          setApiError(errorDetails);
        } else if (Array.isArray(errorDetails)) {
          setApiError(errorDetails.join(', '));
        } else if (typeof errorDetails === 'object' && errorDetails !== null) {
          const formattedErrors = Object.entries(errorDetails).map(
            ([field, reason]) => `${reason}`,
          );
          setApiError(formattedErrors);
        }
      }
      setSaving(false);
      setOpenFail(true);
    }
  };

  return (
    <SidePop zIndex="z-10">
      <div className="flex flex-col bg-vWhite w-full lg:w-[500px] justify-between">
        <div className="flex flex-col w-full p-8 h-full overflow-auto">
          <div className="flex justify-between align-center text-lg font-bold pb-6">
            <p>Add New Manager</p>
            <IconButton textColor="text-vGray" onClick={() => onClose()}>
              <FaXmark />
            </IconButton>
          </div>
          <div>
            <ManagerForm manager={manager} setManager={setManager} edit={true} errors={errors} />
          </div>
        </div>
        <div className="flex px-8 py-4 shadow-inner mt-2 space-x-4 lg:space-x-0">
          <div className="lg:hidden flex w-full">
            <Button bgColor="bg-vWhite" textColor="text-vPurple" onClick={() => onClose()}>
              <p>Cancel</p>
            </Button>
          </div>
          <div className="flex w-full">
            <Button
              bgColor="bg-vOrange"
              textColor="text-vWhite"
              onClick={() => validate() && handleSubmit()}
            >
              {!saving ? 'Create' : <FaSpinner className="flex text-xl animate-spin" />}
            </Button>
          </div>
        </div>
      </div>
      {openFail && (
        <FailModal
          confirm={() => {
            setOpenFail(false);
            if (!apiError) handleSubmit();
          }}
          cancel={() => setOpenFail(false)}
          title="Uh-oh!"
          message={
            apiError
              ? apiError +
              ' ' +
              'It seems there was a hiccup while saving. Let`s double-check the details and try again to ensure everything runs smoothly. Do try again!'
              : 'It seems there was a hiccup while saving. Let`s double-check the details and try again to ensure everything runs smoothly. Do try again!'
          }
        />
      )}
      {openSuccess && (
        <SuccessModal
          confirm={onClose}
          title="New Manager Complete!"
          message={`Manager ${manager?.first_name} ${manager?.last_name} is now uploaded to the database!`}
        />
      )}
    </SidePop>
  );
};

export default CreateManager;

import { currency } from "../options/options";
import { getCompanyId } from "../../config/user";
import { convertToUTCWithPHAdjustment, convertToPHT, convertToPHTdate } from "../timezone";

export const HolidaySerializer = (value: any) => {
  let event: any = value;
  for (let i = 0; i < event.length; i++) {
    event[i].title = event[i].name
    event[i].start = new Date(new Date(event[i].date).setHours(0))
    event[i].end = new Date(new Date(event[i].date).setHours(23, 59, 59))
    event[i].id = `hl${event[i].id}`
  }

  return event;
};

const hex = () => {
  return (Math.floor(Math.random() * (13 - 7) + 7)).toString(16);
}

const locColor = () => {
  return `#${hex()}${hex()}${hex()}${hex()}${hex()}${hex()}`
}

export const AllJobSerializer = (value: any) => {
  let event: any = value;
  let arr: Array<any> = [];

  event = event.filter((ev: any) => ev.shifts.length > 0);
  for (let i = 0; i < event.length; i++) {
    if (event[i].shifts.length > 1) {
      for (let j = 0; j < event[i].shifts.length; j++) {
        let separate: any = structuredClone(event[i]);
        separate.shifts = [event[i].shifts[j]];
        arr.push(separate);
      }
    } else {
      arr.push(event[i]);
    }
  }

  event = arr;
  for (let i = 0; i < event.length; i++) {
    event[i].start = new Date(convertToPHT(event[i].shifts[0].start_datetime))
    event[i].end = new Date(convertToPHT(event[i].shifts[0].end_datetime))
    event[i].shiftId = event[i].shifts[0].id
    event[i].certifications = []
  }

  let group = Object.groupBy(event, (loc: any) => loc.location_id)
  Object.keys(group).map((key: any) => {
    let location: any = group[key]
    let bg = locColor()
    for (let i = 0; i < location.length; i++) {
      location[i].location_color = bg
    }
  })

  return event;
};

export const JobSerializer = (value: any) => {
  let event: any = value;
  let arr: Array<any> = [];
  let arrCert: Array<any> = [];
  event.hourly_rate = String(event.hourly_rate);
  for (let i = 0; i < event.shifts.length; i++) {
    let separate: any = event.shifts[i];
    separate.invites = [];
    separate.added_employees = [];
    separate.break_time = separate.break_time.slice(0, 5)
    separate.start_datetime = new Date(convertToPHT(separate.start_datetime))
    separate.end_datetime = new Date(convertToPHT(separate.end_datetime))
    arr.push(separate);
  }
  event.shifts = arr;

  event.locationObj = structuredClone(event.location);
  event.typeObj = structuredClone(event.type);
  event.usersArr = structuredClone(event.users);
  event.location = event.location_id
  event.type = event.type_id
  if (event.users.length > 0) {
    let arr: any = []
    event.users.map((user: any) => {
      arr.push(typeof user === 'object' ? user.id : user)
      return user
    })
    event.users = arr
  }

  return event;
};

export const JobUserSerializer = (value: any) => {
  let user: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < user.length; i++) {
    let obj: object = {
      value: user[i].id,
      label: user[i].display_name || user[i].name,
      content: user[i]

    }
    arr.push(obj);
  }

  return arr;
}

export const JobShiftUserId = (value: any) => {
  let newJob: any = structuredClone(value);
  let shifts: Array<any> = newJob.shifts;
  let newSplittedShifts: Array<any> = [];

  for (let i = 0; i < shifts.length; i++) {
    const shift = shifts[i];
    const start = new Date(shift.start_datetime);
    const end = new Date(shift.end_datetime);

    const startPH = convertToUTCWithPHAdjustment(start);
    const endPH = convertToUTCWithPHAdjustment(end);

    let splittedShifts = splitShiftByDay(startPH, endPH);

    for (let k = 0; k < splittedShifts.length; k++) {
      let invite: Array<any> = [];
      let add: Array<any> = [];

      if (k === 0) {
        splittedShifts[k].id = shift.id;
      } else {
        if (shift.shift_invites) {
          add = shift.shift_invites.map((inviteItem: any) => inviteItem.user_id);
        }
      }

      if (shift.invites) {
        invite = shift.invites.map((inviteItem: any) => inviteItem.id);
      }
      splittedShifts[k].invites = invite;

      if (shift.added_employees) {
        for (let j = 0; j < shifts[i].added_employees.length; j++) {
          add.push(shifts[i].added_employees[j].id)
        }
      }
      splittedShifts[k].added_employees = add;
      
      if (shift.shift_user_shift){
        splittedShifts[k].retained_shift_user_ids = shift.shift_user_shift.map((user_shift: any) => user_shift.user_id)
      }
      else{
        splittedShifts[k].retained_shift_user_ids = []
      }

      splittedShifts[k].max_slot = shift.max_slot;
      
      newSplittedShifts.push(splittedShifts[k]);
    }

  }

  newJob.shifts = newSplittedShifts;
  delete newJob.is_draft;

  return newJob;
};

function splitShiftByDay(start_datetime: string, end_datetime: string): Array<any> {
  const shifts = [];
  const startDate = new Date(start_datetime);
  const endDate = new Date(end_datetime);
  // Check if the shift is less than a day long
  if (startDate.toDateString() === endDate.toDateString()) {
    shifts.push({ start_datetime, end_datetime });
    return shifts;
  }
  // Loop through each day between the start and end date
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const shiftStart = new Date(currentDate);
    shiftStart.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds(), 0);
    const shiftEnd = new Date(currentDate);
    shiftEnd.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), 0);
    // If the current date is the last day, set the end time from the original shift
    if (shiftEnd.toDateString() === endDate.toDateString()) {
      shifts.push({
        start_datetime: shiftStart.toISOString(),
        end_datetime: endDate.toISOString()
      });
    } else {
      // For intermediate days, set end time to the same as the start day
      shiftEnd.setHours(startDate.getHours() + (endDate.getHours() - startDate.getHours()));
      shifts.push({
        start_datetime: shiftStart.toISOString(),
        end_datetime: shiftEnd.toISOString()
      });
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return shifts;
}

export const JobEditSerializer = (value: any) => {
  let event: any = structuredClone(value)
  event.location = event.location ? event.location.id : 0

  return event;
}

export const JobTypeSerializer = (value: any) => {
  let type: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < type.length; i++) {
    let obj: object = {
      value: type[i].id,
      label: type[i].title
    }
    arr.push(obj);
  }

  return arr;
}

export const JobTemplateSerializer = (value: any) => {
  let template: any = value;
  template.job_title = value.title
  template.job_type = value.type

  return template;
}

export const JobTemplateListSerializer = (value: any) => {
  let template: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < template.length; i++) {
    let arrCert: Array<any> = []
    if (template[i].certificates.length > 0) {
      template[i].certificates.map((certs: any) => {
        arrCert.push(certs.name)
        return certs
      })
    }

    let obj: object = {
      id: template[i].id,
      title: template[i].job_title,
      type: template[i].job_type ? template[i].job_type.title : '',
      description: template[i].job_description,
      rate: String(`${currency} ${template[i].hourly_rate}`),
      instruction: template[i].special_instructions,
      certificates: arrCert
    }
    arr.push(obj);
  }

  return arr;
}

export const JobTemplateEditSerializer = (value: any) => {
  let template: any = structuredClone(value)
  let certificates: Array<any> = []
  template.title = template.job_title
  template.type = template.job_type
  template.hourly_rate = String(template.hourly_rate)

  if (template.certificates.length > 0) {
    template.certificates.map((certs: any) => {
      certificates.push(certs.id)
      return certs
    })
    template.certificates = certificates
  }

  delete template.job_title
  delete template.job_type

  return template;
}

export const JobTemplateOptionSerializer = (value: any) => {
  let template: any = structuredClone(value)
  let arr: Array<any> = []
  for (let i = 0; i < template.length; i++) {
    let certificates: Array<any> = []
    if (template[i].certificates.length > 0) {
      template[i].certificates.map((certs: any) => {
        certificates.push(certs.id)
        return certs
      })
    }

    let obj: object = {
      value: template[i].id,
      label: template[i].job_title,
      content: {
        title: template[i].job_title,
        type: template[i].job_type_id,
        job_description: template[i].job_description,
        hourly_rate: String(template[i].hourly_rate),
        special_instructions: template[i].special_instructions,
        certificates: certificates,
      }
    }
    arr.push(obj);
  }

  return arr;
}

export const JobShiftSerializer = (value: any) => {
  const timeOpt: object = { hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: false };
  let shift: any = structuredClone(value)
  const start = new Date(shift.start_time).toLocaleTimeString('default', timeOpt)
  const end = new Date(shift.end_time).toLocaleTimeString('default', timeOpt)
  shift.start_time = start
  shift.end_time = end
  shift.company = getCompanyId()

  return shift;
}

export const JobEditShiftSerializer = (value: any) => {
  let shift: any = structuredClone(value)
  let sTime = shift.start_time.split(':')
  let eTime = shift.end_time.split(':')
  const start = new Date(new Date().setHours(Number(sTime[0]), Number(sTime[1])))
  const end = new Date(new Date().setHours(Number(eTime[0]), Number(eTime[1])))
  shift.start_time = start
  shift.end_time = end

  return shift;
}

export const JobShiftListSerializer = (value: any) => {
  const timeOpt: object = { hour: 'numeric', minute: '2-digit' };
  let shift: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < shift.length; i++) {
    let sTime = shift[i].start_time.split(':')
    let eTime = shift[i].end_time.split(':')
    let obj: object = {
      id: shift[i].id,
      title: shift[i].block,
      start: new Date(new Date().setHours(Number(sTime[0]), Number(sTime[1]))).toLocaleTimeString('default', timeOpt),
      end: new Date(new Date().setHours(Number(eTime[0]), Number(eTime[1]))).toLocaleTimeString('default', timeOpt),
    }
    arr.push(obj);
  }

  return arr;
}

export const ShiftTemplateOptionSerializer = (value: any) => {
  let template: any = structuredClone(value)
  let arr: Array<any> = []
  for (let i = 0; i < template.length; i++) {
    let sTime = template[i].start_time.split(':')
    let eTime = template[i].end_time.split(':')
    let obj: object = {
      value: template[i].id,
      label: template[i].block,
      content: {
        start_datetime: new Date(new Date().setHours(Number(sTime[0]), Number(sTime[1]))),
        end_datetime: new Date(new Date().setHours(Number(eTime[0]), Number(eTime[1])))
      }
    }
    arr.push(obj);
  }

  return arr;
}

export const ShiftSwapSerializer = (value: any) => {
  let swap: any = structuredClone(value)
  let arr: Array<any> = []
  for (let i = 0; i < swap.length; i++) {
    let obj: object = {
      id: swap[i].id,
      requestor: swap[i].requestor_data.display_name,
      receiver: swap[i].requestee_data.display_name,
      outlet: swap[i].outlet_data.name,
      job_name: swap[i].job_data.title,
      date: new Date(swap[i].selected_date).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' }),
      condition: swap[i].status !== 'reject' ? (swap[i].status !== 'approve' ? 'Pending' : 'Approved') : 'Rejected',
    }
    arr.push(obj);
  }

  return arr;
}

export const ClockInOutSerializer = (value: any) => {
  let clockInOut: any = structuredClone(value)
  let arr: Array<any> = []

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    const weekday = dateObj.toLocaleString('default', { weekday: 'short' });
    return `${month} ${day}, ${year}`;
  };

  const formatTime = (dateString: string) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const getAmPm = (dateString: string) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    return hours >= 12 ? 'PM' : 'AM';
  };

  const formatBreakTime = (timeString: string) => {
    return timeString ? timeString.slice(0, 5) : '';
  };

  for (let i = 0; i < clockInOut.length; i++) {
    let obj: object = {
      id: clockInOut[i].id,
      avatar: clockInOut[i].avatar,
      full_name: clockInOut[i].full_name,
      job_title: clockInOut[i].job_title,
      job: clockInOut[i].job,
      shift: [clockInOut[i].shift ? clockInOut[i].shift : {}],
      job_description: clockInOut[i].job_description,
      special_instructions: clockInOut[i].special_instructions,
      estimated_earnings: String(`${currency} ${clockInOut[i].estimated_earnings}`),
      clock_in_period: getAmPm(clockInOut[i].clock_in),
      clock_in_date: formatDate(clockInOut[i].clock_in),
      clock_out_date: formatDate(clockInOut[i].clock_out),
      clock_in_time: formatTime(clockInOut[i].clock_in),
      clock_out_time: formatTime(clockInOut[i].clock_out),
      clock_in_datetime: clockInOut[i].clock_in,
      clock_out_datetime: clockInOut[i].clock_out,
      break_time: formatBreakTime(clockInOut[i].break_time),
      status: clockInOut[i].status,
      shift_id: clockInOut[i].shift_id,
      user_id: clockInOut[i].user_id,
      is_scan_user_location_qr: clockInOut[i].is_scan_user_location_qr,
      location_id: clockInOut[i].location_id,
      location_name: clockInOut[i].location_name,
    }
    arr.push(obj);
  }

  return arr;
}
export function isValidDateString(dateString: string): boolean {
  // Regular expression to match ISO 8601 date format
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;

  // Check if the string matches the ISO 8601 format
  if (!iso8601Regex.test(dateString)) {
    return false;
  }

  // Convert to date and check if it's valid
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

export const flattenJob = (data: any, parentKey: string = '', result: any = {}) => {
  if (Array.isArray(data)) {
    // Handle arrays by appending index to the key
    data.forEach((item, index) => {
      flattenJob(item, `${parentKey}_${index}`, result);
    });
  } else if (typeof data === 'object' && data !== null) {
    // Handle objects by iterating over their keys
    Object.keys(data).forEach((key) => {
      const value = data[key];
      const fullKey = parentKey ? `${parentKey}_${key}` : key; // Build the new key path

      // If the value is an object or an array, call flatten recursively
      if (typeof value === 'object' && value !== null) {
        flattenJob(value, fullKey, result);
      } else {
        // If it's a primitive or a date, assign it to the result object
        result[fullKey] = value;
      }
    });
  } else {
    // Handle primitives directly (including dates)
    result[parentKey] = data;
  }

  return result;
};

const removeKeys = ['creation_fields_', 'editable_fields_'];

export function readyForCT(data: any): any {
  if (Array.isArray(data)) {
    return null; // or return an empty array based on your requirements
  }

  if (typeof data !== 'object' || data === null) {
    return data; // Return the value if it's not an object
  }

  const result: any = {};

  Object.keys(data).forEach((key) => {
    const containsRemoveKey = removeKeys.some(substring => key.includes(substring));

    // If the key should be removed or if the value is an array, skip it
    if (containsRemoveKey || Array.isArray(data[key])) {
      return; // Skip this key
    }

    const value = data[key];

    // Convert ISO date strings to Date objects
    if (typeof value === 'string' && isValidDateString(value)) {
      result[key] = new Date(value); // Convert to Date object
    }
    // Convert Date objects to Date
    else if (value instanceof Date) {
      result[key] = value;
    }
    // Recursive call for nested objects
    else {
      result[key] = readyForCT(value);
    }
  });

  return result;
}

import { FC, useMemo, useState, useEffect } from 'react';
import {
  FaArrowDown,
  FaCircle,
  FaClockRotateLeft,
  FaFire,
  FaUserTie,
  FaXmark,
} from 'react-icons/fa6';
import IconButton from './IconButton';
import {
  GetListJobApprovals,
  JobAcceptReject,
  ListClockInAndClockOuts,
  ShiftSwapAcceptReject,
  ShowAllShiftSwap,
} from 'pro/api/jobs';
import { FailModal, SuccessModal } from './Modal';
import { ApproveRejectLeaves, ShowAllUserLeaves } from 'pro/api/user_leaves';
import { ClockInOutSerializer } from 'pro/utils/serializer/jobs';
import { Avatar } from '@chakra-ui/react';
import ClockInOutFilter from './ClockInOutFilter';
import ClockInOutSection from './ClockInOutSection';

interface SwapRequestProp {
  setOpenApproval: Function;
}

interface LocationGroup {
  location: string;
  items: any[];
}

interface GroupedData {
  qr: {
    clock_in: LocationGroup[];
    clock_out: LocationGroup[];
  };
  locationQR: {
    clock_in: LocationGroup[];
    clock_out: LocationGroup[];
  };
}

const SwapRequest: FC<SwapRequestProp> = ({ setOpenApproval }) => {
  const [isApproval, setIsApproval] = useState<boolean>(true);
  const [approvalSwap, setApprovalSwap] = useState<Array<object>>([]);
  const [approvalLeave, setApprovalLeave] = useState<Array<object>>([]);
  const [approvalJob, setApprovalJob] = useState<Array<object>>([]);
  const [approvalName, setApprovalName] = useState<string>('');
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openFail, setOpenFail] = useState<boolean>(false);
  const dateOpt: object = { month: 'short', day: 'numeric', year: 'numeric' };
  const dateTimeOpt: object = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const [clockInOuts, setClockInOuts] = useState<Array<object>>([]);
  const [filteredClockInOuts, setFilteredClockInOuts] = useState<any>();
  const [filteredLocationClockInOuts, setFilteredLocationClockInOuts] = useState<any>();
  // const [clockInOutFilter, setClockInOutFilter] = useState<string>('');
  const [clockInOutFilter, setClockInOutFilter] = useState<any>({
      qr_type: '',
      clock_type: '',
    });
  const [filter, setFilter] = useState<any>({
    outlet_id: null,
  });
  const [outlets, setOutlets] = useState<Array<any>>([]);

  useMemo(() => {
    ShowAllShiftSwap()
      .then((res: any) =>
        setApprovalSwap(
          res?.results.filter((swap: any) => !swap.rejected_datetime && !swap.approved_datetime),
        ),
      )
      .catch((err: any) => {});

    ShowAllUserLeaves()
      .then((res: any) => setApprovalLeave(res?.filter((leaves: any) => leaves.status === 1)))
      .catch((err: any) => {});

    GetListJobApprovals()
      .then((res: any) => setApprovalJob(res?.results))
      .catch((err: any) => {});
  }, [openSuccess, openFail]);

  const swapAcceptReject = (decision: any) => {
    ShiftSwapAcceptReject(decision)
      .then(() => setOpenSuccess(true))
      .catch(() => setOpenFail(true));
  };

  const jobAcceptReject = (data: any) => {
    JobAcceptReject(data)
      .then(() => setOpenSuccess(true))
      .catch(() => setOpenFail(true));
  };

  const leaveAcceptReject = (id: any, status: any) => {
    ApproveRejectLeaves(id, { status: status })
      .then(() => setOpenSuccess(true))
      .catch(() => setOpenFail(true));
  };

  useEffect(() => {
    getClockInOutList();
  }, []);

  useEffect(() => {
    const uniqueLocations = clockInOuts
      .filter((val: any, index: any, arr: any) =>
        arr.findIndex((val2: any) => val2.location_name === val.location_name) === index
      )
      .map((item: any) => ({
        id: item.location_id,
        name: item.location_name,
      }));

    setOutlets(uniqueLocations);

    const filteredData = clockInOuts.filter((item: any) => {
      const outletIdMatches = filter.outlet_id ? item.location_id === filter.outlet_id : true;

      if (clockInOutFilter.clock_type === 'Clock In') {
        return item.status === 'Not Started' || item.status === 'On Time' || item.status === 'Late';
      } else if (clockInOutFilter.clock_type === 'Clock Out') {
        return item.status === 'Needs Clock Out';
      } else {
        return outletIdMatches;
      }
    });

    const groupedData: GroupedData = {
      qr: {
        clock_in: [],
        clock_out: []
      },
      locationQR: {
        clock_in: [],
        clock_out: []
      }
    };

    filteredData.forEach((item:any) => {
      const isClockIn = item.status === 'Not Started' || item.status === 'On Time' || item.status === 'Late';
      const isClockOut = item.status === 'Needs Clock Out';
      const locationName = item.location_name || '';
      const isQR = item.is_scan_user_location_qr;

      const group = isQR ? groupedData.qr : groupedData.locationQR;

      if (isClockIn) {
        let locationGroup = group.clock_in.find(loc => loc.location === locationName);
        if (!locationGroup) {
          locationGroup = { location: locationName, items: [] };
          group.clock_in.push(locationGroup);
        }
        locationGroup.items.push(item);
      } 
      else if (isClockOut) {
        let locationGroup = group.clock_out.find(loc => loc.location === locationName);
        if (!locationGroup) {
          locationGroup = { location: locationName, items: [] };
          group.clock_out.push(locationGroup);
        }
        locationGroup.items.push(item);
      }
    });

    setFilteredClockInOuts(groupedData.locationQR);
    setFilteredLocationClockInOuts(groupedData.qr);
  }, [clockInOuts, clockInOutFilter, filter]);

  const getClockInOutList = () => {
    ListClockInAndClockOuts()
      .then((res: any) => setClockInOuts(ClockInOutSerializer(res)))
      .catch(() => setOpenFail(true));
  };

  const refreshData = () => {
    getClockInOutList();
  };

  return (
    <div className="flex fixed bg-black bg-opacity-40 inset-x-0 inset-y-0 flex-row-reverse z-10">
      <div className="flex flex-col bg-vWhite w-full lg:w-1/4 justify-between min-w-0 lg:min-w-[450px]">
        <div className="flex flex-col w-full p-8 h-full overflow-auto">
          <div className="flex justify-end text-lg">
            <IconButton textColor="text-vGray" onClick={() => setOpenApproval(false)}>
              <FaXmark />
            </IconButton>
          </div>
          <div className="flex flex-col space-y-8">
            <div className="flex">
              <button
                className={`flex w-full border-b-2 justify-center pt-6 rounded-t-md ease-in duration-150 hover:bg-vLightGray ${isApproval ? 'text-vOrange border-vOrange font-bold border-b-2' : 'text-vGray'}`}
                onClick={() => setIsApproval(true)}
              >
                <div className="flex space-x-2 pb-4 align-center">
                  <FaFire className="text-lg" />
                  <p className="text-base">APPROVALS</p>
                  <div className="bg-vLightOrange px-3 rounded-full">
                    {(approvalSwap.length > 0 ||
                      approvalLeave.length > 0 ||
                      approvalJob.length > 0) && (
                      <p>{approvalSwap.length + approvalLeave.length + approvalJob.length}</p>
                    )}
                  </div>
                </div>
              </button>
              <button
                className={`flex w-full border-b-2 justify-center pt-6 rounded-t-md ease-in duration-150 hover:bg-vLightGray ${!isApproval ? 'text-vOrange border-vOrange font-bold border-b-2' : 'text-vGray'}`}
                onClick={() => setIsApproval(false)}
              >
                <div className="flex space-x-2 pb-4 align-center">
                  <FaClockRotateLeft className="text-lg" />
                  <p className="text-base">CLOCK IN / OUT</p>
                  <div className="bg-vLightOrange px-3 rounded-full">
                    {clockInOuts.length > 0 && <p>{clockInOuts.length}</p>}
                  </div>
                </div>
              </button>
            </div>
            {isApproval ? (
              <div className="flex flex-col space-y-4">
                {/* <div className='flex space-x-2 justify-end text-vGray text-sm'>  
                  <button className='flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500'>
                    <p className='py-1 px-2'>Approvals</p>
                  </button>
                </div> */}
                {approvalJob.length > 0 &&
                  approvalJob.map((job: any) => (
                    <div
                      key={job.id}
                      className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vBlue"
                    >
                      <div className="flex w-full justify-between">
                        <p className="text-vBlue font-bold">Job Approval</p>
                        <div className="flex space-x-4 text-vGray text-xs">
                          <p>
                            {new Date(job.created_at).toLocaleDateString('default', dateTimeOpt)}
                          </p>
                          <p>{job.location.name}</p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        <div className="flex space-x-4 align-center">
                          <Avatar bg="gray" name={job.location.name} borderRadius={'lg'} size={'md'} src={job.location.logo}/>
                          <p>
                            {job.shifts?.length} Shift{job.shifts?.length !== 1 ? 's' : ''} at{' '}
                            {job.location?.name} requiring approval
                          </p>
                        </div>
                        <div className="flex align-center space-x-4">
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vBlue bg-vLightBlue w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                jobAcceptReject({
                                  job_id: job.id,
                                  accept: true,
                                });
                                setApprovalName(`${job.title} Approved`);
                              }}
                            >
                              <p>Approve</p>
                            </button>
                          </div>
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vPink bg-vLightPink w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                jobAcceptReject({
                                  job_id: job.id,
                                  accept: false,
                                });
                                setApprovalName(`${job.title} Rejected`);
                              }}
                            >
                              <p>Reject</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {approvalSwap.length > 0 &&
                  approvalSwap.map((swap: any) => (
                    <div
                      key={swap.id}
                      className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vBlue"
                    >
                      <div className="flex w-full justify-between">
                        <p className="text-vBlue font-bold">Shift Swap</p>
                        <div className="flex space-x-4 text-vGray text-xs">
                          <p>
                            {new Date(swap.selected_date).toLocaleDateString('default', dateOpt)}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        {/* <div className='flex space-x-4 align-center'>
                        <FaUserTie className='text-2xl'/>
                        <p>{swap.requestee_data.display_name}</p>
                      </div> */}
                        <div className="flex flex-col space-y-2 text-xs">
                          <div className="flex space-x-4 align-center">
                            <FaUserTie className="text-2xl" />
                            <p>{swap.requestor_data.display_name}</p>
                          </div>
                          <FaArrowDown className="w-6" />
                          <div className="flex space-x-4 align-center">
                            <FaUserTie className="text-2xl" />
                            <p>{swap.requestee_data.display_name}</p>
                          </div>
                        </div>
                        <div className="flex align-center space-x-4">
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vBlue bg-vLightBlue w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                swapAcceptReject({
                                  shift_request: swap.id,
                                  decision: 'approve',
                                });
                                setApprovalName(`${swap.job_data.title} Approved`);
                              }}
                            >
                              <p>Approve</p>
                            </button>
                          </div>
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vPink bg-vLightPink w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                swapAcceptReject({
                                  shift_request: swap.id,
                                  decision: 'reject',
                                });
                                setApprovalName(`${swap.job_data.title} Rejected`);
                              }}
                            >
                              <p>Reject</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {approvalLeave.length > 0 &&
                  approvalLeave.map((leave: any) => (
                    <div
                      key={leave.id}
                      className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vGreen"
                    >
                      <div className="flex w-full justify-between">
                        <p className="text-vGreen font-bold">Leave Request</p>
                        <div className="flex space-x-4 text-vGray text-xs">
                          <p>
                            {new Date(leave.leave_start_date).toLocaleDateString(
                              'default',
                              dateOpt,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        <div className="flex space-x-4 align-center text-xs">
                          <FaUserTie className="text-2xl" />
                          <p>{leave.user_name}</p>
                        </div>
                        {/* <div className='flex flex-col space-y-2'>
                        <div className='flex space-x-4 align-center'>
                          <FaUserTie className='text-2xl'/>
                          <p>{swap.requestee_data.display_name}</p>
                        </div>
                        <div className='flex space-x-4 align-center'>
                          <FaUserTie className='text-2xl'/>
                          <p>{swap.requestor_data.display_name}</p>
                        </div>
                      </div> */}
                        <div className="flex align-center space-x-4">
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vBlue bg-vLightBlue w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                leaveAcceptReject(leave.id, '2');
                                setApprovalName(`${leave.reason} Approved`);
                              }}
                            >
                              <p>Approve</p>
                            </button>
                          </div>
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vPink bg-vLightPink w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                leaveAcceptReject(leave.id, '3');
                                setApprovalName(`${leave.reason} Rejected`);
                              }}
                            >
                              <p>Reject</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col lg:flex-row gap-3 lg:gap-2">
                  <ClockInOutFilter
                    paramsFilter={setClockInOutFilter}
                    outlets={[{ id: 0, name: 'All' }, ...outlets]}
                    listFilter={setFilter}
                  />
                </div>
                {(clockInOutFilter.qr_type === 'Location QR' || clockInOutFilter.qr_type === 'All' || clockInOutFilter.qr_type === '') && (
                  <ClockInOutSection refreshData={refreshData} qrType='Location QR Code' filteredData={filteredLocationClockInOuts} clockType={clockInOutFilter.clock_type}/>
                )}
                {(clockInOutFilter.qr_type === 'Job QR' || clockInOutFilter.qr_type === 'All' || clockInOutFilter.qr_type === '') && (
                  <ClockInOutSection refreshData={refreshData} qrType='Job QR Code' filteredData={filteredClockInOuts} clockType={clockInOutFilter.clock_type}/>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {openFail && (
        <FailModal
          confirm={() => setOpenFail((curr: boolean) => !curr)}
          cancel={() => setOpenFail((curr: boolean) => !curr)}
          title="Uh-oh!"
          message="We encounter some problem."
        />
      )}
      {openSuccess && (
        <SuccessModal
          confirm={() => setOpenSuccess((curr: boolean) => !curr)}
          title="Approval Complete!"
          message={`${approvalName}`}
        />
      )}
    </div>
  );
};

export default SwapRequest;

import { FC } from 'react';

interface IconButtonProp {
  textColor: string,
  onClick: Function,
  children: any,
};

const IconButton: FC<IconButtonProp> = ({textColor, onClick, children}) => {

  return (
    <button className={`ease-in duration-150 rounded-lg p-2 hover:bg-vLightGray ${textColor}`} 
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};

export default IconButton;
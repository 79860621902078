export interface NewJob {
	title: string,
	type: number,
	location: number,
	job_description: string,
	hourly_rate: string,
	special_instructions: string,
	task: number,
	users: Array<number>,
	certificates: Array<number>,
	template: number|null,
	shifts: [
		{
			max_slot: number,
			start_datetime: Date,
			end_datetime: Date,
			break_time: string,
			invites: []
			added_employees: []
		}
	]
}

export const JobInterface: NewJob = {
	title: '',
	type: 0,
	location: 0,
	job_description: '',
	hourly_rate: '0',
	special_instructions: '',
	task: 0,
	users: [],
	certificates: [],
	template: null,
	shifts: [
		{
			max_slot: 1,
			start_datetime: new Date(new Date().setHours(8,0,0,0)),
			end_datetime: new Date(new Date().setHours(17,0,0,0)),
			break_time: '01:00',
			invites: [],
			added_employees: []
		}
	]
};

export interface NewJobTemplate {
	title: string,
	type: number,
	job_description: string,
	hourly_rate: string,
	special_instructions: string,
	certificates: []
}

export const JobTemplateInterface: NewJobTemplate = {
	title: '',
	type: 0,
	job_description: '',
	hourly_rate: '0',
	special_instructions: '',
	certificates: [],
};

export interface NewShiftTemplate {
	block: string,
	start_time: Date,
	end_time: Date
}

export const ShiftTemplateInterface: NewShiftTemplate = {
	block: '',
	start_time: new Date(new Date().setHours(8,0,0,0)),
	end_time: new Date(new Date().setHours(17,0,0,0))
};

export interface NewClockOutTemplate {
	break_time: string,
	start_time: Date,
	end_time: Date,
	addition: number,
	deduction: number,
	rating: number,
	feedback: string,
}

export const ClockOutInterface: NewClockOutTemplate = {
	break_time: '00:00',
	start_time: new Date(new Date().setHours(8,0,0,0)),
	end_time: new Date(new Date().setHours(17,0,0,0)),
	addition: 0,
	deduction: 0,
	rating: 0,
	feedback: '',
};
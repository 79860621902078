import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { currency } from '../options/options';
import { convertToPHTdate } from '../timezone';

export const ManagerListSerializer = (value: any) => {
  let user: any = value;
  let arr: Array<any> = [];
  for (let i = 0; i < user.length; i++) {
    let locationArr: Array<any> = [];
    if (user[i].locations.length > 0) {
      user[i].locations.map((location: any) => locationArr.push(location.name));
    }
    let obj: object = {
      id: user[i].id,
      name: {
        title: user[i].display_name,
        image: user[i].avatar,
      },
      manager_id: user[i].id,
      role: user[i].role ? user[i].role.name : '',
      locations: String(locationArr).replace(',', ', '),
      contact: user[i].contact_number,
      email: user[i].email,
      is_enable: user[i].status ? 'Enable' : 'Disable',
    };
    arr.push(obj);
  }

  return arr;
};

export const ManagerSerializer = (value: any) => {
  let user: any = structuredClone(value);
  user.contact_number = user.contact_number;
  if (typeof user.avatar === 'string' || !user.avatar) {
    delete user.avatar;
  }

  return user;
};

export const ManagerOutletSerializer = (value: any) => {
  let outlet: any = value;
  let arr: Array<any> = [];
  for (let i = 0; i < outlet.length; i++) {
    let obj: object = {
      value: outlet[i].id,
      label: outlet[i].name,
    };
    arr.push(obj);
  }

  return arr;
};

export const WorkerListSerializer = (value: any) => {
  let user: any = value;
  let arr: Array<any> = [];
  for (let i = 0; i < user.length; i++) {
    let obj: object = {
      id: user[i].id,
      name: {
        title: user[i].display_name,
        image: user[i].avatar,
      },
      worker_id: user[i].id,
      contact: user[i].contact_number,
      email: user[i].email,
      location: user[i]?.locations?.length > 0 ? user[i].locations[0].name : '',
      govt: user[i].identity_documents?.length > 0 ? user[i].identity_documents[0].type : '',
      is_enable: user[i]?.status ? 'Enable' : 'Disable',
    };
    arr.push(obj);
  }

  return arr;
};

const decimalToString = (val: any) => {
  return !val ? '0' : val.split('.')[0];
};

export const WorkerEditSerializer = (value: any) => {
  let user: any = structuredClone(value)
  user.locations = value.locations.length > 0 ? value.locations[0].id : 0
  // user.employee_benefits_maximum_working_hours_per_week = 0;

  if (value?.identity_documents?.length > 0) {
    value?.identity_documents?.map((document: any, index: number) => {
      if (document?.field_name === 'government' && document?.field_name !== null) {
        user.government_id_type = document?.type;
        user.government_id_number = document?.unique_id;
        user.government_id_front_id = document?.id;
        user.government_id_front = document?.file_url_front;
        user.government_id_back_id = document?.id;
        user.government_id_back = document?.file_url_back;
      } else if (
        document?.field_name !== 'identity_documents_employment_documents' &&
        document?.field_name !== 'identity_documents_pre_employement_requirements' &&
        document?.field_name !== null
      ) {
        user[document?.field_name + '_id'] = document?.id;
        user[document?.field_name] = document.file_url_front;
      }
      return document;
    });
  }

  if (value?.banks?.length > 0) {
    value?.banks?.map((bank: any) => {
      user.bank_id = bank?.id;
      user.bank = bank?.bank?.bank_name;
      user.bank_branch = bank?.branch_name;
      user.account_name = bank?.account_name;
      user.account_number = bank?.account_number;
      user.bank_code = bank?.bank_code;
      return bank;
    });
  }

  if (Object.keys(value?.address || {})?.length > 0) {
    user.address_id = value?.address.id;
    user.region = value?.address?.region;
    user.city = value?.address?.city;
    user.district = value?.address?.district;
    user.postal_code = value?.address?.postal_code;
    user.line = value?.address?.line;
  }

  if (value?.contacts?.length > 0) {
    value?.contacts?.map((emergency: any) => {
      user.emergency_contact_id = emergency?.id;
      user.emergency_contact_name = emergency?.name;
      user.emergency_contact_relationship = emergency?.relationship;
      user.emergency_contact_number = emergency?.contact_number;
      return emergency;
    });
  }

  if (Object.keys(value?.compensation_details || {})?.length > 0) {
    user.employee_benefits_id = value?.compensation_details?.id;
    user.employee_benefits_worker_type = value?.compensation_details?.worker_type;
    user.employee_benefits_salary_rate_type = value?.compensation_details?.salary_rate_type;
    user.employee_benefits_annual_leave = value?.compensation_details?.annual_leave;
    user.employee_benefits_sick_leave = value?.compensation_details?.sick_leave;
    user.employee_benefits_parental_leave = value?.compensation_details?.parental_leave;
    user.employee_benefits_marital_leave = value?.compensation_details?.marital_leave;
    user.employee_benefits_bereavement_leave = value?.compensation_details?.bereavement_leave;
    user.employee_benefits_other_leave = value?.compensation_details?.other_leave_type;
    user.employee_benefits_other_leave_temp = value?.compensation_details?.other_leave_type;
    user.employee_benefits_other_leave_value = decimalToString(
      value?.compensation_details?.other_leave_value,
    );
    user.employee_benefits_gross_basic_pay = decimalToString(
      value?.compensation_details?.gross_basic_pay,
    );
    user.employee_benefits_hdmf_loan = decimalToString(
      value?.compensation_details?.hdmf_loan,
    );
    user.employee_benefits_sss_loan = decimalToString(
      value?.compensation_details?.sss_loan,
    );
    user.employee_benefits_maximum_working_hours_per_week =
      value?.compensation_details?.maximum_working_hours_per_week;
    user.employee_benefits_monthly_working_hours =
      value?.compensation_details?.monthly_working_hours;
    user.employee_benefits_working_days = value?.compensation_details?.working_days;
    user.employee_benefits_thirteenth_month_bonus =
      value?.compensation_details?.thirteenth_month_bonus;
    user.employee_benefits_clothing_allowance = decimalToString(
      value?.compensation_details?.clothing_allowance,
    );
    user.employee_benefits_laundry_allowance = decimalToString(
      value?.compensation_details?.laundry_allowance,
    );
    user.employee_benefits_rice_subsidy = decimalToString(
      value?.compensation_details?.rice_subsidy,
    );
    user.employee_benefits_dependents_medical_allowance = decimalToString(
      value?.compensation_details?.dependents_medical_allowance,
    );
    user.employee_benefits_meal_allowance = decimalToString(
      value?.compensation_details?.meal_allowance,
    );
    user.employee_benefits_productivity_incentive_schemes = decimalToString(
      value?.compensation_details?.productivity_incentive_schemes,
    );
  }

  delete user?.identity_documents;
  delete user?.banks;
  delete user?.compensation_details;
  delete user?.contacts;
  delete user?.address;

  return user;
};

export const WorkerSerializer = (value: any) => {
  let user: any = structuredClone(value);
  user.contact_number = user.contact_number;
  if (!user.date_of_birth || user.date_of_birth === '') {
    delete user.date_of_birth;
  } else {
    user.date_of_birth = dayjs(user.date_of_birth).format('YYYY-MM-DD');
  }
  if (user.date_of_birth === '') {
    delete user.emergency_contact_number;
  } else {
    user.emergency_contact_number = user.emergency_contact_number;
  }
  if (user.employee_benefits_other_leave_temp === '') {
    delete user.emergency_contact_number;
  } else {
    user.employee_benefits_other_leave =
      user.employee_benefits_other_leave_temp !== ''
        ? user.employee_benefits_other_leave_temp
        : user.employee_benefits_other_leave;
  }
  Object.keys(user).map((u: any) => {
    if (user[u] === '' || user[u] === null) {
      delete user[u];
    }
  });
  // if (user.locations === 0) {delete user.locations}
  if (user.gender === 0) {
    delete user.gender;
  }
  if (typeof user.avatar === 'string' || !user.avatar) {
    delete user.avatar;
  }
  if (typeof user.government_id_front === 'string' || !user.government_id_front) {
    delete user.government_id_front;
    delete user.government_id_front_id;
  }
  if (typeof user.government_id_back === 'string' || !user.government_id_back) {
    delete user.government_id_back;
    delete user.government_id_back_id;
  }
  if (
    typeof user.identity_documents_police_clearance === 'string' ||
    !user.identity_documents_police_clearance
  ) {
    delete user.identity_documents_police_clearance;
    delete user.identity_documents_police_clearance_id;
  }
  if (
    typeof user.identity_documents_nbi_clearance === 'string' ||
    !user.identity_documents_nbi_clearance
  ) {
    delete user.identity_documents_nbi_clearance;
    delete user.identity_documents_nbi_clearance_id;
  }
  if (typeof user.identity_documents_bir_2303 === 'string' || !user.identity_documents_bir_2303) {
    delete user.identity_documents_bir_2303;
    delete user.identity_documents_bir_2303_id;
  }
  if (typeof user.identity_documents_valid_id === 'string' || !user.identity_documents_valid_id) {
    delete user.identity_documents_valid_id;
    delete user.identity_documents_valid_id_id;
  }
  if (
    typeof user.identity_documents_other_document === 'string' ||
    !user.identity_documents_other_document
  ) {
    delete user.identity_documents_other_document;
    delete user.identity_documents_other_document_id;
  }

  return user;
};

export const WorkerHistoryListSerializer = (value: any) => {
  const arr: Array<any> = [];
  const dateOpt: object = { month: 'short', day: 'numeric', year: 'numeric' };
  for (let i = 0; i < value?.length; i++) {
    const obj: object = {
      id: value?.[i]?.id,
      date: `${convertToPHTdate(value?.[i]?.start_datetime)} - ${convertToPHTdate(value?.[i]?.end_datetime)}`,
      location: value?.[i]?.location,
      title: value?.[i]?.title,
      rating: value?.[i]?.rating,
      job_status: value?.[i]?.job_status,
      applicant_status: value?.[i]?.applicant_status,
    };
    arr.push(obj);
  }
  return arr;
};

export const WorkerPayslipSerializer = (value: any) => {
  let payroll: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < payroll.length; i++) {
    let obj: object = {
      id: payroll[i].id,
      date: convertToPHTdate(payroll[i].payroll.created_at),
      payslip_id: payroll[i].id,
      payroll_id: payroll[i].payroll.id,
      gross: `${currency} ${payroll[i].gross_pay}`,
      net: `${currency} ${payroll[i].net_pay}`,
      condition: String(payroll[i].status).charAt(0).toUpperCase() + String(payroll[i].status).slice(1)
    }
    arr.push(obj);
  }

  return arr;
}

export const WorkerBenefitsSerializer = (value: any) => {
  let payroll: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < payroll.length; i++) {
    let obj: object = {
      date: convertToPHTdate(payroll[i].payroll.created_at),
      payslip_id: payroll[i].id,
      period: `${convertToPHTdate(payroll[i].payroll.start_date)} - ${convertToPHTdate(payroll[i].payroll.end_date)}`,
      gross: `${currency} ${payroll[i].gross_pay}`,
      net: `${currency} ${payroll[i].net_pay}`,
      sss: `${currency} ${payroll[i].sss}`,
      phic: `${currency} ${payroll[i].phic}`,
      hdmf: `${currency} ${payroll[i].hdmf}`,
      condition: String(payroll[i].status).charAt(0).toUpperCase() + String(payroll[i].status).slice(1)
    }
    arr.push(obj);
  }

  return arr;
}

export const WorkerAdjustmentSerializer = (value: any) => {
  let payroll: any = value;
  let arr: Array<any> = []
  for (let i = 0; i < payroll.length; i++) {
    let obj: object = {
      date: convertToPHTdate(payroll[i].adjustment_date),
      // adjustment_id: payroll[i].id,
      type: String(payroll[i].adjustment_type).charAt(0).toUpperCase() + String(payroll[i].adjustment_type).slice(1),
      amount: `${currency} ${payroll[i].amount}`,
      reason: payroll[i].reason,
    }
    arr.push(obj);
  }

  return arr;
}
import { FaXmark } from 'react-icons/fa6';
import SuccessModalLogo from 'assets/pro/images/successmodal.png';
import FailModalLogo from 'assets/pro/images/failmodal.png';
import UpdateModalLogo from 'assets/pro/images/updatemodal.png';
import ApproveLeaveLogo from 'assets/pro/images/leaveApproveImage.svg';
import { FC, ReactNode, useState } from 'react';
import Button from './Button';
import IconButton from './IconButton';
import TextInput from './TextInput';
import CheckBox from './CheckBox';
import { Option } from 'pro/models/option';
import ReactSelect from 'react-select';
import { Text } from '@chakra-ui/react';

// modal container
interface ModalProp {
  children: any;
}

export const Modal: FC<ModalProp> = ({ children }) => {
  return (
    <div className="fixed z-20 bg-black bg-opacity-40 inset-x-0 inset-y-0 flex">
      {/* modal container */}
      <div className="flex h-full w-full align-center justify-center px-5">
        <div className="flex flex-col p-6 bg-vWhite rounded-xl w-full lg:w-1/4 space-y-4">
          {children}
        </div>
      </div>
    </div>
  );
};

// template confirmation modal
interface InputModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: string;
  roleLabel: string;
  setRoleLabel: Function;
}

export const InputModal: FC<InputModalProp> = ({
  confirm,
  cancel,
  title,
  message,
  roleLabel,
  setRoleLabel,
}) => {
  return (
    <Modal>
      <div className="flex justify-between align-center text-lg font-bold">
        <p>{title}</p>
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <p className="text-xs text-vGray">{message}</p>
      <TextInput
        value={roleLabel}
        onChange={(val: any) => setRoleLabel(val)}
        edit={true}
        template={false}
        placeholder="Input Name"
        errors={null}
      />
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vWhite" textColor="text-vPurple" onClick={() => cancel()}>
          <p>Cancel</p>
        </Button>
        <Button
          bgColor="bg-vOrange"
          textColor="text-vWhite"
          onClick={() => {
            setRoleLabel('');
            confirm();
          }}
        >
          <p>Save</p>
        </Button>
      </div>
    </Modal>
  );
};

// success modal
interface SuccessModalProp {
  confirm: Function;
  title: string;
  message: string;
}

export const SuccessModal: FC<SuccessModalProp> = ({ confirm, title, message }) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => confirm()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <p className="text-xs text-vGray">{message}</p>
        <img className="flex py-2" src={SuccessModalLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Proceed</p>
        </Button>
      </div>
    </Modal>
  );
};

// fail modal
interface FailModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: string;
  buttonMessage?: string;
}

export const FailModal: FC<FailModalProp> = ({
  confirm,
  cancel,
  title,
  message,
  buttonMessage,
}) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <p className="text-xs text-vGray">{message}</p>
        <img className="flex py-2" src={FailModalLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>{buttonMessage ? buttonMessage : 'Try again'}</p>
        </Button>
      </div>
    </Modal>
  );
};

// delete modal
interface DeleteModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: string;
  warning: string;
}

export const DeleteModal: FC<DeleteModalProp> = ({ confirm, cancel, title, message, warning }) => {
  const [check, setCheck] = useState<boolean>(false);

  return (
    <Modal>
      <div className="flex justify-between align-center text-lg font-bold">
        <p>{title}</p>
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <p className="text-xs text-vGray">{message}</p>
      <div className="flex rounded-md border-vGrayWhite p-3 space-x-2 shadow-sm">
        <CheckBox
          checked={check}
          onChange={(val: any) => setCheck((curr: boolean) => !curr)}
          edit={true}
          template={false}
          accentColor="accent-vDarkOrange"
        />
        <p className="text-vGray">{warning}</p>
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vWhite" textColor="text-vPurple" onClick={() => cancel()}>
          <p>Cancel</p>
        </Button>
        <div className={`flex w-full ${!check && 'grayscale pointer-events-none'}`}>
          <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
            <p>Delete</p>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

// update success modal
interface UpdateModalProp {
  confirm: Function;
  title: string;
  message: string;
}

export const UpdateModal: FC<UpdateModalProp> = ({ confirm, title, message }) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => confirm()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <p className="text-xs text-vGray">{message}</p>
        <img className="flex py-2" src={UpdateModalLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Proceed</p>
        </Button>
      </div>
    </Modal>
  );
};

// check unsave modal
interface UnsaveModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: string;
}

export const UnsaveModal: FC<UnsaveModalProp> = ({ confirm, cancel, title, message }) => {
  return (
    <Modal>
      <div className="flex justify-between align-center text-lg font-bold">
        <p>{title}</p>
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <p className="text-xs text-vGray">{message}</p>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vWhite" textColor="text-vPurple" onClick={() => cancel()}>
          <p>Cancel</p>
        </Button>
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Save</p>
        </Button>
      </div>
    </Modal>
  );
};

// fail to upload modal
interface FailUploadModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: string;
  list: string;
}

export const FailUploadModal: FC<FailUploadModalProp> = ({
  confirm,
  cancel,
  title,
  message,
  list,
}) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <p className="text-xs text-vGray">{message}</p>
        <p className="text-xs text-vRed font-bold py-2">{list}</p>
        <img className="flex py-2" src={FailModalLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Try again</p>
        </Button>
      </div>
    </Modal>
  );
};

interface UpdateStatusModalProp {
  confirm: (status: number) => void;
  cancel: Function;
  title: string;
  message: string;
  options: Array<Option>;
}

export const UpdateStatusModal: FC<UpdateStatusModalProp> = ({
  confirm,
  cancel,
  title,
  message,
  options,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton
          textColor='text-vGray'
          onClick={() => cancel()}
        >
          <FaXmark/>
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <p className="text-small text-vBlack">{message}</p>
        <div className={`flex w-full my-4 bg-vWhite border-2 border-vLightGray rounded-md align-center justify-center`}>
          <ReactSelect
            className='w-full'
            placeholder='Select Status'
            options={options} 
            onChange={(val: any) => {
              setSelectedStatus(val);
            }}
          />
        </div>
      </div>
      <div className="flex space-x-2 w-full">
        <Button
          bgColor='bg-vOrange'
          textColor='text-vWhite'
          onClick={() => confirm(selectedStatus)}
        > 
          <p>Proceed</p>
        </Button>
      </div>
    </Modal>
  );
};

interface ApproveLeaveModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: ReactNode;
}

export const ApproveLeaveModal: FC<ApproveLeaveModalProp> = ({ confirm, cancel, title, message }) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <Text className="text-xs text-vGray" style={{ textAlign: 'center' }}>
          <span>{message}</span>
        </Text>
        <img className="flex py-2" src={ApproveLeaveLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Proceed</p>
        </Button>
      </div>
    </Modal>
  );
};

interface RejectLeaveModalProp {
  confirm: Function;
  cancel: Function;
  title: string;
  message: ReactNode;
}

export const RejectLeaveModal: FC<RejectLeaveModalProp> = ({ confirm, cancel, title, message }) => {
  return (
    <Modal>
      <div className="flex justify-end text-lg font-bold">
        <IconButton textColor="text-vGray" onClick={() => cancel()}>
          <FaXmark />
        </IconButton>
      </div>
      <div className="flex flex-col align-center text-lg font-bold">
        <p>{title}</p>
      </div>
      <div className="flex flex-col align-center">
        <Text className="text-xs text-vGray" style={{ textAlign: 'center' }}>
          <span>{message}</span>
        </Text>
        <img className="flex py-2" src={UpdateModalLogo} alt="" />
      </div>
      <div className="flex space-x-2 w-full">
        <Button bgColor="bg-vOrange" textColor="text-vWhite" onClick={() => confirm()}>
          <p>Proceed</p>
        </Button>
      </div>
    </Modal>
  );
};

import { FC } from 'react';
import DatePicker from 'react-datepicker';

interface TimeSelectProp {
  value: any,
  onChange: Function,
  label: string
};

const TimeSelect: FC<TimeSelectProp> = ({value, onChange, label}) => {

  return (
    <DatePicker className='flex w-full bg-transparent focus:outline-none text-center'
      showTimeSelect 
      showTimeSelectOnly 
      timeIntervals={10} 
      timeCaption={label}
      dateFormat='h:mm aa' 
      selected={value} 
      onChange={(e: any) => onChange(e)}
    />
  );
};

export default TimeSelect;
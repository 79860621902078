import { FC, useMemo, useState, useEffect } from 'react';
import { FaAsterisk } from 'react-icons/fa6';
import { ShowAllOutlets } from '../../api/outlet';
import { ManagerOutletSerializer } from '../../utils/serializer/workforce';
import { GetManagerTypes } from '../../api/workforce';
import LogoUploader from 'pro/components/LogoUploader';
import TextInput from 'pro/components/TextInput';
import OptionSelect from 'pro/components/OptionSelect';
import { locationActions } from 'store/modules/location';
import { useDispatch, useSelector } from 'react-redux';
import { currentLocationsSelector, currentManagerTypesSelector } from 'store/modules/auth';
import { keptQueryActions } from 'store/modules/kept-query';
interface ManagerFormProp {
  manager: any;
  setManager: Function;
  edit: boolean;
  errors?: any;
}

const ManagerForm: FC<ManagerFormProp> = ({ manager, setManager, edit, errors }) => {
  const [outletLocation, setOutletLocation] = useState<Array<object>>([]);
  const [managerType, setManagerType] = useState<Array<object>>([]);
  const dispatch = useDispatch();
  const stored_locations = useSelector(currentLocationsSelector);
  const stored_managertypes = useSelector(currentManagerTypesSelector);

  useEffect(() => {
    const fetchOutletLocations = async () => {
      try {
        const res = await ShowAllOutlets();
        const locations = ManagerOutletSerializer(res?.results);
        setOutletLocation(locations);
        dispatch(locationActions.updateLocations(res?.results));
      } catch (err) {
        console.error('Error fetching outlet locations:', err);
      }
    };
    const getManagerTypes = async () => {
      try {
        if (stored_managertypes && stored_managertypes.length > 0) {
          setManagerType(stored_managertypes);
        } else {
          const res = await GetManagerTypes();
          const managerTypes = ManagerOutletSerializer(res);
          setManagerType(managerTypes);
          dispatch(keptQueryActions.cacheManagerTypes(managerTypes));
        }
      } catch (err) {
        console.error('Error fetching outlet locations:', err);
      }
    };
    if (stored_locations && stored_locations.length > 0) {
      setOutletLocation(ManagerOutletSerializer(stored_locations));
    } else {
      fetchOutletLocations();
    }
    getManagerTypes();
  }, []);

  return (
    <form className="flex flex-col space-y-6 align-center">
      <div className={`flex flex-col w-full pb-8 ${!edit && 'pointer-events-none'}`}>
        <label className="flex pb-2 font-bold">Profile</label>
        <LogoUploader
          id={'avatar'}
          value={manager?.avatar}
          onChange={(val: any) =>
            val.target.files.length > 0 && setManager({ ...manager, avatar: val.target.files[0] })
          }
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">First Name</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <TextInput
          value={manager?.first_name}
          onChange={(val: any) => setManager({ ...manager, first_name: val })}
          edit={edit}
          template={false}
          placeholder="Please Enter First Name"
          errors={errors?.first_name}
        />
        {/* {errors.first_name && <span className="text-red-500">{errors.first_name}</span>} */}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Middle Name</label>
        </div>
        <TextInput
          value={manager?.middle_name}
          onChange={(val: any) => setManager({ ...manager, middle_name: val })}
          edit={edit}
          template={false}
          placeholder="Please Enter Middle Name"
          errors={null}
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Last Name</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <TextInput
          value={manager?.last_name}
          onChange={(val: any) => setManager({ ...manager, last_name: val })}
          edit={edit}
          template={false}
          placeholder="Please Enter Last Name"
          errors={errors?.last_name}
        />
        {/* {errors.last_name && <span className="text-red-500">{errors.last_name}</span>} */}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Manager Type</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <OptionSelect
          value={managerType.filter((val: any) => val.value === manager?.role_id)}
          onChange={(val: any) => setManager({ ...manager, role: {...manager?.role, id: val.value}, role_id: val.value })}
          edit={edit}
          template={false}
          options={managerType}
          placeholder="Select Manager Type"
          multi={false}
          errors={errors?.role}
        />
        {/* {errors.role && <span className="text-red-500">{errors.role}</span>} */}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Outlet Location</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <OptionSelect
          value={outletLocation.filter((val: any) => manager?.locations.includes(val.value))}
          onChange={(val: any) => {
            let arrLocations: Array<any> = [];
            val.map((v: any) => {
              arrLocations.push(v.value);
              return v;
            });
            setManager({ ...manager, locations: arrLocations });
          }}
          edit={edit}
          template={false}
          options={outletLocation}
          placeholder="Select Outlet Location"
          multi={true}
          errors={errors?.locations}
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Mobile Number</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <div
          className={`flex border-2 px-3 py-2 ease-in duration-150 rounded-md border-vGrayWhite space-x-1 shadow-sm outline-transparent focus-within:border-vOrange focus-within:border-[1px] ${!edit && 'bg-vGrayWhite pointer-events-none'}`}
        >
          <p>+63</p>
          <input
            className="w-full pl-1 focus:outline-vWhite bg-transparent"
            type="text"
            placeholder="Please Enter Mobile Number"
            // maxLength={9}
            value={manager?.contact_number}
            onChange={(val: any) =>
              val.target.value.match(/^[0-9]*$/) &&
              setManager({ ...manager, contact_number: val.target.value })
            }
          />
        </div>
        {errors.contact_number && (
          <p className="text-red-500 text-xs pt-1">{errors.contact_number}</p>
        )}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <label className="flex pb-2 font-bold">Work Email</label>
          <FaAsterisk className="text-[6px] text-vOrange" />
        </div>
        <TextInput
          value={manager?.email}
          onChange={(val: any) => setManager({ ...manager, email: val })}
          edit={edit}
          template={false}
          placeholder="Please Enter Work Email"
          errors={errors.email}
        />
        {/* {errors.email && <span className="text-red-500">{errors.email}</span>} */}
      </div>
    </form>
  );
};

export default ManagerForm;

import { config } from '../config/config'
import { getPermission } from '../config/user';
import { OutletSerializer } from '../utils/serializer/outlet';
import axios from 'axios';
import { authStorage } from 'configs/browser-storage';
import clevertap from 'clevertap-web-sdk';
import { flattenJob, readyForCT } from 'pro/utils/functions';

// show all outlets
export const ShowAllOutlets = async (expandUsers: boolean = true, filter: string = '') => {

  try {
    const url = new URL(`${config.API_URL}/location/`);
    if (expandUsers) {
      url.searchParams.append('expand', 'users');
    }
    if (filter !== '') {
      url.searchParams.append('is_enable', filter);
    }

    const response = await axios.get(url.toString(), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(10)?.action
        'X-Action': 'list-locations'
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }

};

// create new outlet
export const SubmitNewOutlet = async (outlet: object) => {
  const form = new FormData();
  const newOutlet: any = OutletSerializer(outlet);
  Object.keys(newOutlet).map((outlet: any) => {
    if (outlet === 'users') {
      newOutlet[outlet].map((manager: any) => {
        form.append(outlet, manager)
        return manager
      })
    } else {
      form.append(outlet, newOutlet[outlet])
    }
    return outlet;
  });
  try {
    const response = await axios.post(`${config.API_URL}/location/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(12)?.action
        'X-Action': 'create-location'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Outlet (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

// get selected outlet
export const GetOutlet = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/location/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(11)?.action
        'X-Action': 'show-location-detail'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update outlet selected
export const UpdateOutlet = async (outlet: object) => {

  const form = new FormData();
  const newOutlet: any = OutletSerializer(outlet);
  Object.keys(newOutlet).map((outlet: any) => {
    if (outlet === 'users') {
      newOutlet[outlet].map((manager: any) => {
        form.append(outlet, manager)
        return manager
      })
    } else {
      form.append(outlet, newOutlet[outlet])
    }
    return outlet;
  });

  try {
    const response = await axios.put(`${config.API_URL}/location/${newOutlet.id}/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(13)?.action
        'X-Action': 'update-location'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Outlet (Web)", readyForCT(flattenedData));

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const StatusOutlet = async (status: object, id: number) => {

  try {
    const response = await axios.patch(`${config.API_URL}/location/${id}/`, status, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(13)?.action
        'X-Action': 'update-location'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Outlet Status (Web)", readyForCT(flattenedData));

    return response.data;
  } catch (err) {
    throw err;
  }

};

// delete outlet selected
export const DeleteOutlet = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/location/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(14)?.action
        'X-Action': 'delete-location'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Delete Outlet (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

// download template csv for outlet
export const DownloadTemplateOutlet = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/location/download-csv-template/`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(17)?.action
        'X-Action': 'download-location'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// upload outlet csv
export const UploadCsvOutlet = async (csv: File) => {

  const newCsv = new FormData();
  newCsv.append('file', csv);

  try {
    const response = await axios.post(`${config.API_URL}/location/upload-csv/`, newCsv, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(16)?.action
        'X-Action': 'upload-location'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Create Outlets [CSV] (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

// download selected for outlet
export const DownloadListOutlet = async (list: any) => {

  const newList = structuredClone(list);

  try {
    const response = await axios.get(`${config.API_URL}/location/download-csv/?location_ids=${String(newList).toString()}`, {
      headers: {
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(17)?.action
        'X-Action': 'download-location'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// show all users with filter
export const GetManagers = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/users/?managers-only=true`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};
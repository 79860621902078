import { ENV } from 'configs';
import { DATE_BE, DEFAULT_PAGE_SIZE } from 'constant';
import dayjs from 'dayjs';
import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import round from 'lodash/round';
import { ErrorDescription, ListAxiosResponse, Order, Payload, Query } from 'types';
import {
  isNullable,
  mapSorterToParams,
  removeMultipleWhitespace,
  removeNullishAndEmpty,
} from 'utils';

import { IApplicant } from 'models/applicant';
import { Role } from 'models/user';

import { Sorter } from 'components/organisms/Table';

import { userStorage } from 'configs/browser-storage';

export const mapPaginationParams = (pagination: Payload['pagination']) => {
  return {
    limit: pagination?.pageSize || DEFAULT_PAGE_SIZE,
    page: pagination?.current || 1,
  };
};

export const mapPaginationState = (data: ListAxiosResponse<any>['data']['data']) => {
  return {
    current: data.page || 1,
    pageSize: data.limit || DEFAULT_PAGE_SIZE,
    total: data.total,
    lastPage: data.last_page,
  };
};

export const mapErrorDescriptions = (
  scheme: Record<string, string>,
  source?: ErrorDescription | null,
) => {
  if (!source) return null;
  return mapKeys(pick(source, Object.keys(scheme)), (value, key) => scheme[key]);
};

if (process.env.NODE_ENV === 'development') {
  window.calcPercentForItems = (...items: number[]) => {
    const total = items.reduce((acc, curr) => acc + +curr, 0);
    let percent = 100;
    const result = items.map((item, index) => {
      const rest = Math.round((+item / total) * 100);
      if (index !== items.length - 1) {
        percent -= rest;
      }
      return {
        value: item,
        percent: index === items.length - 1 ? percent : rest,
      };
    });
  };
}

export const mapFullName = (data: {
  first_name?: string;
  last_name?: string;
  [x: string]: any;
}) => {
  if (data.full_name) return data.full_name;
  return `${data.first_name} ${data.last_name}`;
};
export const mapContactNumber = (data: {
  contact_number: string | number;
  country_code?: string | number;
  [x: string]: any;
}): string => {
  const displayCountryCode = data.country_code || ENV.COUNTRY_CODE;
  return `+${displayCountryCode}${data.contact_number}`;
};

export const getPathByRole = (
  path: Partial<Record<Role, string>>,
  role?: Role,
): string | undefined => {
  // const currentRole = role ?? authStorage.value?.user.role.type;
  const currentRole = role ?? userStorage.value?.role.type;
  if (!currentRole) return undefined;
  return path[currentRole];
};

export const mapQueryToParams = (
  query: Query,
  pagination: Payload['pagination'],
  addition?: { [key: string | number]: string | number | undefined | null },
  config?: { sortDefaultByCreatedAt?: boolean; sortDefault?: Sorter; hidePagination?: boolean },
) => {
  const { filter, sort, search, columnFilter } = query;
  const { sortDefaultByCreatedAt, sortDefault, hidePagination = false } = config || {};

  const displaySort =
    sort ??
    sortDefault ??
    (sortDefaultByCreatedAt && {
      key: 'created_at',
      order: Order.Descend,
      field: 'created_at',
    });

  const result = {
    filter_types: filter ?? undefined,
    key_word: removeMultipleWhitespace(search) ?? undefined,
    ...(displaySort ? mapSorterToParams(displaySort) : {}),
    ...columnFilter,
    ...addition,
    ...(!hidePagination ? mapPaginationParams(pagination) : {}),
  };
  return removeNullishAndEmpty(result);
};

export const concatParams = (...args: string[]) => {
  return args.filter((item) => !isNullable(item, { includeEmpty: true })).join();
};

export const encodeURL = (url: string) => {
  if (!url) return;
  return url.replace(' ', '%');
};

export const calculateAge = (date: string) =>
  dayjs().tz(ENV.TIMEZONE).diff(dayjs(date, DATE_BE).tz(ENV.TIMEZONE, true), 'years');

export const roundingNumber = (data?: number | string | null) => {
  if (isNullable(data)) return 'N/A';
  if (typeof data === 'number') {
    return round(data, 2);
  }
  if (typeof data === 'string') {
    const dataNumber = parseFloat(data);
    return round(dataNumber, 2);
  }
};

export const displayUniqueId = (applicant: IApplicant) => {
  if (!applicant.identity_type) return applicant.unique_id;
  return `${applicant.identity_type} ${applicant.unique_id}`;
};

export const mapDisplayTimeChart = (month: string, year: number | string) => `${month} ${year}`;

export const mapDataToOptions = <
  DataType extends object,
  L extends keyof DataType,
  V extends keyof DataType,
  I extends keyof DataType,
>(
  data: DataType[] | null,
  labelIndex: L,
  valueIndex: V,
  imageIndex?: I | null,
) => {
  if (!data) return [];
  return data.map((item) => ({
    label: item[labelIndex],
    value: item[valueIndex],
    image: imageIndex ? item[imageIndex] : null,
  }));
};

import axios from 'axios';
import { config } from '../config/config';
import { authStorage } from 'configs/browser-storage';
export const setCompany = async (companyID: number) => {
  try {
    const response = await axios.patch(`${config.API_URL}/users/set-company/`, { company: companyID }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      }
    });
  } catch (error: any) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicantState } from './type';

const initialState: ApplicantState = {
  pagination: {
    next: null,
    prev: null
  },
  workers: [],
  summary: {
    totalActive: 0,
    totalDisable: 0,
    total: 0,
    init: false,
  },
};

export const { actions: applicantActions, reducer: applicantReducer } = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    updateWorkerPagination(state, action: PayloadAction<ApplicantState['pagination']>) {
      state.pagination = action.payload;
    },
    updateWorkers(state, action: PayloadAction<ApplicantState['workers']>) {
      state.workers = action.payload;
    },
    addNewWorker(state, action: PayloadAction<ApplicantState['workers']>) {
      state.workers.unshift(action.payload);
    },
    updateSummary(state, action: PayloadAction<Omit<ApplicantState['summary'], 'init'>>) {
      const { payload } = action;
      state.summary = {
        ...payload,
        init: false,
      };
    },
  },
});

export * from './type';
export * from './selector';

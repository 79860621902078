import { FC } from 'react';

interface CheckBoxProp {
  checked: boolean,
  onChange: Function,
  edit?: boolean
  template?: boolean
  accentColor?: string
};

const CheckBox: FC<CheckBoxProp> = ({checked, onChange, edit, template, accentColor}) => {

  return (
    <input 
      className={`${accentColor} ${(!edit || template) && 'accent-vGrayWhite pointer-events-none'}`} 
      type='checkbox'
      checked={checked} 
      onChange={(e: any) => onChange(e.target.checked)}
    />
  );
};

export default CheckBox;
export enum Permissions {
  // Jobs
  LIST_ALL_JOBS = 36,
  VIEW_JOB_DETAIL = 37,
  CREATE_JOB = 38,
  UPDATE_JOB = 39,
  DELETE_JOB = 40,

  // Job Template
  LIST_JOB_TEMPLATE = 49,
  CREATE_JOB_TEMPLATE = 50,
  UPDATE_JOB_TEMPLATE = 51,
  VIEW_JOB_TEMPLATE = 52,

  // Companies
  LIST_COMPANIES = 10,
  VIEW_COMPANY_DETAIL = 11,
  CREATE_COMPANY = 12,
  UPDATE_COMPANY = 13,

  // Locations/Outlet
  LIST_LOCATIONS = 14,
  VIEW_LOCATION_DEATAIL = 15,
  CREATE_LOCATION = 16,
  UPDATE_LOCATION = 17,
  DELETE_LOCATION = 18,
  ENABLE_LOCATION = 19,
  UPLOAD_LOCATION = 20,
  DOWNLOAD_LOCATION = 21,
  CREATE_UPDATE_COST_BREAKDOWN = 67,

  // Shift
  SHIFT_TEMPLATES_LIST = 41,
  SHIFT_TEMPLATES_CREATE = 42,
  SHIFT_TEMPLATES_UPDATE = 43,
  SHIFT_TEMPLATES_VIEW = 44,
  LIST_SHIFT = 48,
  ACCEPT_APPLICATION = 45,
  APPLY_TO_SHIFT = 46,
  DELETE_SHIFT = 47,

  // Swap Request
  LIST_SWAP_REQUEST = 53,
  CREATE_SWAP_REQUEST = 54,
  UPDATE_SWAP_REQUEST = 55,
  VIEW_SWAP_REQUEST = 56,

  // Rules
  LIST_AUTOMATED_RULES = 19,
  CREATE_AUTOMATED_RULES = 20,
  UPDATE_AUTOMATED_RULES = 21,
  DELETE_AUTOMATED_RULES = 22,

  // Payroll
  LIST_PAYROLL = 71,
  CREATE_PAYROLL = 72,
  UPDATE_PAYROLL = 73,
  VIEW_PAYROLL = 74,

  // Pay Template
  LIST_PAY_TEMPLATE = 75,
  CREATE_PAY_TEMPLATE = 76,
  UPDATE_PAY_TEMPLATE = 77,
  DELETE_PAY_TEMPLATE = 78,

  //Job Approve Reject
  APPROVE_REJECT_JOB = 79,

  // Workforce Manager
  LIST_WORKFORCE_MANAGER = 22,
  CREATE_WORKFORCE_MANAGER = 23,
  UPDATE_WORKFORCE_MANAGER = 24,
  DELETE_WORKFORCE_MANAGER = 25,
  UPLOAD_WORKFORCE_MANAGER = 26,
  DOWNLOAD_WORKFORCE_MANAGER = 27,
  GET_MANAGER_TYPES = 35,

  // Workforce Worker
  LIST_WORKFORCE_WORKER = 28,
  CREATE_WORKFORCE_WORKER = 29,
  RETRIEVE_WORKFORCE_WORKER = 30,
  UPDATE_WORKFORCE_WORKER = 31,
  DELETE_WORKFORCE_WORKER = 32,
  UPLOAD_WORKFORCE_WORKER = 33,
  DOWNLOAD_WORKFORCE_WORKER = 34,

  // Attendance
  CLOCK_IN = 64,
  CREATE_CLOCK_IN = 62,
  SCAN_AUTO_ATTENDANCE = 66,
  CREATE_CLOCK_OUT = 63,
  CLOCK_OUT = 65,

  // Leaves
  LEAVES_LIST = 57,
  LEAVES_CREATE = 58,
  LEAVES_UPDATE = 59,
  LEAVES_VIEW = 60,
  LEAVES_APPROVE_REJECT = 61,

  // Report
  LIST_REPORT = 68,
  VIEW_REPORT = 69,

  // Dashboard
  DASHBOARD = 70,

  // Permission
  UPDATE_PERMISSION = 9,

  // User Role
  LIST_ROLES = 5,
  UPDATE_ROLE = 6,
  DELETE_ROLE = 7,
  CREATE_ROLE = 8,

}

export type SystemPermissions = Permissions;
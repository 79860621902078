import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertToPHT = (utcTime: any) => {
  return dayjs.utc(utcTime).tz('Asia/Manila').format('YYYY-MM-DD HH:mm:ss');
}

export const convertToPHTdate = (utcTime: any) => {
  const date = convertToUTCWithPHAdjustment(utcTime);
  return dayjs(date).format('YYYY-MM-DD');
}

export const convertToPHTtime = (utcTime: any) => {
  const date = convertToUTCWithPHAdjustment(utcTime);
  return dayjs(date).format('HH:mm:ss');
}

function timeDifferenceInMilliseconds() {
  const localDate = new Date();
  const phDate = new Date(localDate.toLocaleString("en-US", { timeZone: "Asia/Manila" }));
  const timeDifferenceInMilliseconds = phDate.getTime() - localDate.getTime();
  return timeDifferenceInMilliseconds
}

export const convertToUTCWithPHAdjustment = (selectedTimeInJST: Date) => {
  const TimeDifferenceInMilliseconds = timeDifferenceInMilliseconds();
  const selectedDate = new Date(selectedTimeInJST); // Example: "2024-10-18T07:00:00+09:00"

  // Convert selected date from Local to UTC
  const utcDate = new Date(selectedDate.getTime() - (TimeDifferenceInMilliseconds));
  return utcDate.toISOString(); // Save this in UTC
}
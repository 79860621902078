import { FC } from 'react';
import { FaCloudArrowUp } from 'react-icons/fa6';

interface LogoUploaderProp {
  id: string,
  value: any,
  onChange: Function
};

const LogoUploader: FC<LogoUploaderProp> = ({id, value, onChange}) => {

  return (
    <div className='flex w-40 h-40'>
      {value ?
        <label htmlFor={id} className='flex cursor-pointer w-full h-full hover:border-vOrange hover:border-[1px] rounded-xl'>
          <img className='flex h-full w-full object-cover rounded-xl' src={typeof value === 'string' ? value : URL.createObjectURL(value)} alt=''/>
        </label>
        :
        <label htmlFor={id} className='flex cursor-pointer w-full h-full justify-center align-center border-2 border-dashed rounded-xl hover:border-vOrange hover:border-[1px]'>
          <FaCloudArrowUp className='text-4xl'/>
        </label>
      }
      <input
        className="hidden"
        id={id}
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e: any) => onChange(e)}
      />
    </div>
  );
};

export default LogoUploader;
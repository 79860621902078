export const currency = '₱';

export const breakOptions = [
  { value: '00:00', label: '0 Hr' },
  { value: '01:00', label: '1 Hr' },
  { value: '02:00', label: '2 Hrs' },
  { value: '03:00', label: '3 Hrs' },
];

export const templateOptions = [
  {
    content: {
      name: 'Waiter',
      cash: 0,
      bank_transfer: 0,
      cheque: 0,
      date_of_week: '',
      type: 0,
      outletName: 0,
      description: 'Test Description',
      earnings: 100,
      instruction: '',
      task: 0,
      areaManager: 0,
      outletManager: 0,
      certifications: [],
      shifts: [
        {
          startDate: new Date(new Date().setHours(8, 0, 0, 0)),
          endDate: new Date(new Date().setHours(17, 0, 0, 0)),
          slots: [],
          breakTime: '01:00',
          shift: '',
          users: []
        }
      ]
    }, value: 1, label: 'Template 1'
  },
  {
    content: {
      name: 'Barista',
      cash: 0,
      bank_transfer: 0,
      cheque: 0,
      date_of_week: '',
      type: 0,
      outletName: 0,
      description: 'Test Description for Template 2',
      earnings: 210,
      instruction: '',
      task: 0,
      areaManager: 0,
      outletManager: 0,
      certifications: [],
      shifts: [
        {
          startDate: new Date(new Date().setHours(8, 0, 0, 0)),
          endDate: new Date(new Date().setHours(17, 0, 0, 0)),
          slots: [],
          breakTime: '01:00',
          shift: '',
          users: []
        }
      ]
    }, value: 2, label: 'Template 2'
  }
];

export const outletOptions = [
  { id: 1, label: 'Outlet 1' },
  { id: 2, label: 'Outlet 2' },
];

export const jobOptions = [
  { value: 1, label: 'Waiter' },
  { value: 2, label: 'Barista' },
  { value: 3, label: 'Carpenter' },
  { value: 4, label: 'Welder' },
  { value: 5, label: 'Barber' },
];

export const outletNameOptions = [
  { value: 1, label: 'Outlet 1' },
  { value: 2, label: 'Outlet 2' }
];

export const taskOptions = [
  { value: 1, label: 'Task 1' },
];

export const gender = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
];

export const govtOptions = [
  { value: 1, label: 'SSS Unified Multi-Purpose ID (UMID)' },
  { value: 2, label: 'Driver`s License' },
  { value: 3, label: 'Passport' },
  { value: 4, label: 'Philippine Identification (PhilID / ePhilID)' },
  { value: 5, label: 'PhilHealth ID' },
  { value: 6, label: 'Postal ID' },
  { value: 7, label: 'Voter`s ID' },
];

export const relativeOptions = [
  { value: 1, label: 'Spouse' },
  { value: 2, label: 'Parents' },
  { value: 3, label: 'Child' },
  { value: 4, label: 'Legal Guardian' },
];

export const bankOptions = [
  { value: 1, label: 'Bank' },
  { value: 2, label: 'E-Wallet' },
];

export const workerType = [
  { value: 1, label: 'Hourly Wage Rate' },
  { value: 2, label: 'Daily Wage Rate' },
  { value: 3, label: 'Bi-Monthly Wage Rate' },
  { value: 4, label: 'Monthly Wage Rate' },
];

export const contractType = [
  { value: 'hourly_rate', label: 'Part Time' },
  { value: 'daily_rate', label: 'Full Time' },
];

export const otherLeave = [
  { value: 1, label: 'Military Leave' },
  { value: 2, label: 'Special Leave Benefit for Women' },
  { value: 3, label: 'Leave for Victims of Violence Against Women and Their Children' },
];

export const viewUser = [
  {
    id: 1,
    name: 'Dell Jackson',
    email: 'Dell@example.com',
    contact_number: +63123456789,
    user_rating: null,
    hrs_worked: 0,
    no_of_jobs: 0,
    total_earnings: 0,
    company: 1,
    role: 6,
    company_role_permissions: [],
    location: 1,
    display_name: 'Dell Jackson'
  },
  {
    id: 2,
    name: 'Sophia Hernandez',
    email: 'Sophia@example.com',
    contact_number: +63123456789,
    user_rating: null,
    hrs_worked: 0,
    no_of_jobs: 0,
    total_earnings: 0,
    company: 1,
    role: 6,
    company_role_permissions: [],
    location: 1,
    display_name: 'Sophia Hernandez'
  },
]

export const viewCostUser = [
  { value: 1, label: 'Dell Jackson' },
  { value: 2, label: 'Sophia Hernandez' },
]

export const payslipOptions = [
  {
    id: 1,
    date: '15 Sep 2024 - 30 Sep 2024',
    payslip_id: 'ID30062024',
    gross: `${currency} 800.00`,
    net: `${currency} 800.00`,
    status: 'paid'
  },
]

export const jobHistoryOptions = [
  {
    id: 1,
    date: '15 Sep 2024 - 30 Sep 2024',
    location: 'Pharmacy @ NCIS, NUH Medical Centre, Zone B, Level 9',
    title: 'Service Crew',
    rating: '4.4',
    job_status: 'Active',
    applicant_status: 'Cancelled'
  },
]

export const payrollOptions = [
  {
    id: 1,
    name: {
      title: 'John Goh',
    },
    outlet: 'Fairprice Chai Chee',
    birth: '27 Sep 1988',
    plan_type: 'Hourly Rate',
    rate: `${currency} 10/hr`,
    gross: `${currency} 800/hr`,
    variable: `${currency} 810/hr`,
  },
]

export const payrollGovtOptions = [
  {
    id: 1,
    payslip_id: 1,
    generated_date: '2024-08-01',
    payroll_period: '2024-07-01 to 2024-07-31',
    gross_salary: 50000,
    net_salary: 45000,
    sss: 1000,
    philHealth: 500,
    hdmf: 300,
    payroll_status: 'Processed',
  },
]

export const benefitsOptions = [
  {
    id: 1,
    date: '15 Sep 2024 - 30 Sep 2024',
    location: 'Pharmacy @ NCIS, NUH Medical Centre, Zone B, Level 9',
    title: 'Service Crew',
    rating: '4.4',
    job_status: 'Active',
    applicant_status: 'Cancelled'
  },
]

export const jobTemplateOptions = [
  {
    id: 1,
    title: 'Barista',
    type: 'F&B',
    description: 'Make coffee',
    earnings: '10',
    instructions: 'Bring milk',
    certificates: ['']
  },
]

export const timeShiftOptions = [
  { value: 1, label: 'AM' },
  { value: 2, label: 'PM' },
  { value: 3, label: 'Night' },
  { value: 4, label: 'All Day' },
]

export const rateShiftOptions = [
  { value: 1, label: 'Fixed Rate' },
  { value: 2, label: 'Multiplier' },
]

export const transactionOptions = [
  {
    id: 1,
    ref_no: 'REF#1234',
    date: 'May 1',
    description: 'Lorem ipsum dolor sit amet, consectetur ',
    net: `${currency} 500.00`,
    payment: `${currency} 500.00`,
    job_status: 'Paid',
  },
  {
    id: 2,
    ref_no: 'REF#1234',
    date: 'April 15',
    description: 'Lorem ipsum dolor sit amet, consectetur ',
    net: `${currency} 500.00`,
    payment: `${currency} 500.00`,
    job_status: 'Paid',
  },
  {
    id: 3,
    ref_no: 'REF#1234',
    date: 'April 1',
    description: 'Lorem ipsum dolor sit amet, consectetur ',
    net: `${currency} 500.00`,
    payment: `${currency} 500.00`,
    job_status: 'Pending',
  },
]

export const invoiceOptions = [
  {
    id: 1,
    invoice_no: 'REF#1234',
    account_name: 'John Doe',
    date: 'May 1',
    total: `${currency} 500.00`,
    due: `${currency} 500.00`,
    job_status: 'Paid',
  },
  {
    id: 2,
    invoice_no: 'REF#1234',
    account_name: 'John Doe',
    date: 'May 15',
    total: `${currency} 500.00`,
    due: `${currency} 500.00`,
    job_status: 'Paid',
  },
  {
    id: 3,
    invoice_no: 'REF#1234',
    account_name: 'John Doe',
    date: 'April 1',
    total: `${currency} 500.00`,
    due: `${currency} 500.00`,
    job_status: 'Pending',
  },
  {
    id: 4,
    invoice_no: 'REF#1234',
    account_name: 'John Doe',
    date: 'April 15',
    total: `${currency} 500.00`,
    due: `${currency} 500.00`,
    job_status: 'Pending',
  },
]

export const approvalOptions = [
  {
    id: 1,
    requestor: 'Bruce Wayne',
    receiver: 'John Doe',
    outlet: 'Outlet 1',
    job_name: 'Waiter',
    date: '15 May 2024',
  },
  {
    id: 2,
    requestor: 'Bruce Wayne',
    receiver: 'John Doe',
    outlet: 'Outlet 1',
    job_name: 'Waiter',
    date: '15 May 2024',
  },
  {
    id: 3,
    requestor: 'Bruce Wayne',
    receiver: 'John Doe',
    outlet: 'Outlet 1',
    job_name: 'Waiter',
    date: '15 May 2024',
  },
  {
    id: 4,
    requestor: 'Bruce Wayne',
    receiver: 'John Doe',
    outlet: 'Outlet 1',
    job_name: 'Waiter',
    date: '15 May 2024',
  },
]

export const additionReason = [
  { value: 'Salary', label: 'Salary' },
  { value: 'CBA', label: 'CBA' },
  { value: 'Reimbursements', label: 'Reimbursements' },
  { value: 'Gifts', label: 'Gifts' },
  { value: 'Rice Subsidy', label: 'Rice Subsidy' },
  { value: 'Medical Cash Allowance', label: 'Medical Cash Allowance' },
  { value: 'Uniform and Clothing Allowance', label: 'Uniform and Clothing Allowance' },
  { value: 'Medical Assistance', label: 'Medical Assistance' },
  { value: 'Laundry Allowance', label: 'Laundry Allowance' },
  { value: 'Employees Achievement Award', label: 'Employees Achievement Award' },
]

export const deductionReason = [
  { value: 'Salary', label: 'Salary' },
  { value: 'Loans', label: 'Loans' },
]
import { FC, useEffect, useState } from 'react';
import { currency } from '../../utils/options/options';
import DropDown from '../../components/DropDown';
import { FaAngleDown, FaAngleUp, FaRegStar, FaStar } from 'react-icons/fa6';
import Select from 'react-select';
import { breakOptions } from '../../utils/options/options';
import TimeSelect from 'pro/components/TimeSelect';
import TextInput from 'pro/components/TextInput';
import IconButton from 'pro/components/IconButton';
import TextArea from 'pro/components/TextArea';

interface UserJobDetailProp {
  job: any;
  shifts: any;
  userStatus: string;
  clockout: any;
  setClockout: Function;
  editable: boolean;
}

const UserJobDetail: FC<UserJobDetailProp> = ({
  job,
  shifts,
  userStatus,
  clockout,
  setClockout,
  editable,
}: any) => {
  const [isShowRating, setIsShowRating] = useState<boolean>(true);
  const [isShowFeedback, setIsShowFeedback] = useState<boolean>(true);
  const dateOpt: object = { month: 'short', day: 'numeric', year: 'numeric' };
  const timeOpt: object = { hour: 'numeric', minute: '2-digit' };
  const starRates: Array<number> = [1, 2, 3, 4, 5];

  // validate value if date
  const toDate = (value: any) => {
    return typeof value === 'string' ? new Date(value) : value;
  };

  return (
    <div className="flex flex-col">
      {userStatus === 'Ready to Clock In' ? (
        <div className="flex flex-col w-full space-y-6">
          {shifts?.map((shift: any, index: any) => (
            <div
              key={index}
              className="flex flex-col w-full space-y-1 bg-vGrayWhite rounded-md p-4"
            >
              {/* view job shift */}
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full">
                  {shift.id && <span style={{ float: 'inline-start' }}>Shift ID:{shift.id}</span>}
                  <p className="text-center">
                    {new Date(shift.start_datetime).toLocaleDateString('default', dateOpt)} -{' '}
                    {new Date(shift.end_datetime).toLocaleDateString('default', dateOpt)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex w-full justify-between text-center">
                  <div className="flex flex-col w-full">
                    <p className="text-[10px] text-vGreen pb-1">Clock In</p>
                    <p>{new Date(shift.start_datetime).toLocaleTimeString('default', timeOpt)}</p>
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-[10px] text-vSkyBlue pb-1">Clock Out</p>
                    <p>{new Date(shift.end_datetime).toLocaleTimeString('default', timeOpt)}</p>
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-[10px] text-vOrange pb-1">Break Time</p>
                    <p>{Number(shift.break_time.split(':')[0])} Hr</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-col space-y-6">
            {/* view job details */}
            <div className="flex flex-col space-y-1">
              <div className="flex justify-between align-center">
                <p className="font-bold">Job Title</p>
              </div>
              <div className="w-full flex">
                <div className="flex flex-col p-4 bg-vGrayWhite rounded-md w-full text-xs">
                  <p className="font-bold">{job?.title}</p>
                </div>
              </div>
            </div>
            {/* <DropDown 
              header={{
                name: 'Earnings Per Hour'
              }} 
              content={[
                {
                  title: `${currency} ${job?.hourly_rate}`,
                },
              ]}
            /> */}
            <DropDown
              header={{
                name: 'Job Description',
              }}
              content={[
                {
                  body: job?.job_description,
                },
              ]}
            />
            <DropDown
              header={{
                name: 'Special Instructions',
              }}
              content={[
                {
                  body: job?.special_instructions !== '' ? job?.special_instructions : 'None',
                },
              ]}
            />
            {/* <DropDown
              header={{
                name: 'Task'
              }}
              content={[
                {
                  body: job?.task,
                },
              ]}
            /> */}
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col w-full space-y-6 ${(userStatus === 'Clocked Out' || !editable) && 'pointer-events-none'}`}
        >
          {shifts.map((job: any, index: any) => (
            <div key={index} className="flex flex-col w-full space-y-2">
              {/* job shift */}
              <div className="flex w-full p-2 bg-vGrayWhite rounded-md">
                {job.id && <span style={{ float: 'inline-start' }}>Shift ID:{job.id}</span>}
                <p className="flex w-full justify-center">
                  {new Date(job.start_datetime).toLocaleDateString('default', dateOpt)} -{' '}
                  {new Date(job.end_datetime).toLocaleDateString('default', dateOpt)}
                </p>
              </div>
              <div className="flex w-full space-x-1 h-16">
                <div className="flex flex-col w-full p-2 bg-vGrayWhite rounded-md text-center space-y-1">
                  <p className="text-[10px] text-vGreen">Clock In</p>
                  <TimeSelect
                    value={toDate(clockout.start_time)}
                    onChange={(val: any) => {
                      console.info(val);
                      setClockout({ ...clockout, start_time: val });
                    }}
                    label="Start"
                  />
                </div>
                <div className="flex flex-col w-full p-2 bg-vGrayWhite rounded-md text-center space-y-1">
                  <p className="text-[10px] text-vSkyBlue">Clock Out</p>
                  <TimeSelect
                    value={toDate(clockout.end_time)}
                    onChange={(val: any) => setClockout({ ...clockout, end_time: val })}
                    label="End"
                  />
                </div>
                <div className="flex flex-col w-full p-2 bg-vGrayWhite rounded-md text-center space-y-1">
                  <p className="text-[10px] text-vOrange">Break Time</p>
                  <Select
                    className="shift text-center"
                    placeholder="Break"
                    options={breakOptions}
                    value={breakOptions.filter((val: any) => val.value === clockout.break_time)}
                    onChange={(v: any) => setClockout({ ...clockout, break_time: v.value })}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-col space-y-6">
            {/* deduction and additional fees for user */}
            <div className="flex flex-col">
              <div className="flex justify-between align-center">
                <p className="font-bold w-full">Salary Deductions</p>
                <div className="flex w-full align-center space-x-2">
                  <p>{currency}</p>
                  <TextInput
                    value={clockout.deduction}
                    onChange={(val: any) =>
                      val.match(/^[0-9]*$/) && setClockout({ ...clockout, deduction: val })
                    }
                    edit={userStatus !== 'Clocked Out'}
                    template={false}
                    placeholder="Deduction Amount"
                    errors={null}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between align-center">
                <p className="font-bold w-full">Salary Additions</p>
                <div className="flex w-full align-center space-x-2">
                  <p>{currency}</p>
                  <TextInput
                    value={clockout.addition}
                    onChange={(val: any) =>
                      val.match(/^[0-9]*$/) && setClockout({ ...clockout, addition: val })
                    }
                    edit={userStatus !== 'Clocked Out'}
                    template={false}
                    placeholder="Additional Amount"
                    errors={null}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex justify-between align-center">
                <p className="font-bold">Job Title</p>
              </div>
              <div className="w-full flex">
                <div className="flex flex-col p-4 bg-vGrayWhite rounded-md w-full text-xs">
                  <p className="font-bold">{job?.title}</p>
                </div>
              </div>
            </div>
            {/* <DropDown 
              header={{
                name: 'Earnings Per Hour'
              }} 
              content={[
                {
                  title: `${currency} ${job?.hourly_rate}`,
                },
              ]}
            /> */}
            {/* <DropDown
              header={{
                name: 'Task'
              }}
              content={[
                {
                  body: job?.task,
                },
              ]}
            /> */}
            <div className="flex flex-col space-y-1">
              {/* rate worker */}
              <div className="flex justify-between align-center">
                <p className="flex w-full font-bold">Worker Rating</p>
                <IconButton
                  textColor="text-vGray"
                  onClick={() => setIsShowRating((curr: boolean) => !curr)}
                >
                  {isShowRating ? <FaAngleUp /> : <FaAngleDown />}
                </IconButton>
                {/* <button className='text-vGray pl-4' onClick={() => setIsShowRating((curr: boolean) => !curr)}>
                  {isShowRating ?  <FaAngleUp/> : <FaAngleDown/>}
                </button> */}
              </div>
              <div
                className={`p-4 rounded-md ${userStatus === 'Clocked Out' && 'bg-vGrayWhite grayscale'} ${isShowRating ? 'flex' : 'hidden'}`}
              >
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex w-full justify-between text-4xl">
                    {starRates.map((rate: any) => (
                      <button
                        key={rate}
                        className="flex w-full justify-center"
                        onClick={() => setClockout({ ...clockout, rating: rate })}
                      >
                        {clockout.rating >= rate ? (
                          <FaStar className="text-vOrange" />
                        ) : (
                          <FaRegStar className="text-vGray" />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              {/* feedback for worker */}
              <div className="flex justify-between align-center">
                <p className="flex w-full font-bold">Worker Feedback</p>
                <IconButton
                  textColor="text-vGray"
                  onClick={() => setIsShowFeedback((curr: boolean) => !curr)}
                >
                  {isShowFeedback ? <FaAngleUp /> : <FaAngleDown />}
                </IconButton>
              </div>
              <div className={`${isShowFeedback ? 'flex' : 'hidden'}`}>
                <TextArea
                  value={clockout.feedback}
                  onChange={(val: any) => setClockout({ ...clockout, feedback: val })}
                  edit={userStatus !== 'Clocked Out'}
                  template={false}
                  placeholder="Input Feedback"
                  rows={5}
                  errors={null}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserJobDetail;

export interface ClockInOutFilterTemplate {
  qr_type: string;
  clock_type: string;
  outlet_id: number | null;
}

export const ClockInOutFilterTemplate: ClockInOutFilterTemplate = {
  qr_type: '',
  clock_type: '',
  outlet_id: null
};
// import { useCurrentFeature } from 'hooks/components/auth/useCurrentUserFeature';
// import { SystemFeatures } from 'pro/types/features';
import React, { useMemo } from 'react';
// import { useSelector } from 'react-redux';
import { Loading } from 'components/molecules';
import { useRouter, useTranslate } from 'hooks/common';
import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';
import { PATH_NAME } from 'configs';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { useCurrentPermission } from 'pro/hooks/useCurrentPermissionPro';
import { SystemPermissions } from 'pro/types/permissions';
import { useCurrentRoleId } from 'pro/auth/useCurrentRoleId';
import { useCurrentRole } from 'hooks/components/auth';
import { Role } from 'models/user';

interface FeatureRouteGuardProps {
  requiredPermissions?: SystemPermissions[];
  requiredRole?: Role[];
  children?: React.ReactNode;
}

const FeatureRouteGuard: React.FC<FeatureRouteGuardProps> = ({ requiredPermissions, requiredRole, children, ...props }) => {
  const router = useRouter();
  const t = useTranslate();
  const [currentUserPermissions, checkHasPermission] = useCurrentPermission();
  const currentUserRoleId = useCurrentRoleId();
  const currentUserRole =  useCurrentRole();
  const isAuthorized = useMemo(() => {
    const isValidPermission = requiredPermissions?.length
      ? checkHasPermission(requiredPermissions)
      : true;

      const isValidRole = requiredRole
      ? requiredRole.includes(currentUserRole!)
      : true;

    return isValidPermission && isValidRole;
  }, [checkHasPermission, requiredPermissions, currentUserRole, requiredRole]);

  if (!currentUserPermissions || !currentUserRoleId) {
    return <Loading h="100%" loading tip="Authorizing..." {...props} />;
  }

  if (isAuthorized === true) {
    return <>{children}</>;
  }

  if (router.pathname === PATH_NAME.ROOT) {
    return <RedirectFirstPage />;
  }
  return (
    <Box p="20px">
      <Alert status="error">
        <AlertIcon />
        {t('message.youNoPermissionAccessPage')}
      </Alert>
    </Box>
  );
};

export default FeatureRouteGuard;

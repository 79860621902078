import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import classNames from 'classnames';
import { t } from 'i18next';
import RCPicker from 'rc-picker';
import { GenerateConfig } from 'rc-picker/lib/generate/index';
import * as React from 'react';
import { Components, getTimeProps } from '.';
import enUS from '../locale/en_US';
import { getPlaceholder } from '../util';
import { CustomPickerProps } from './index';
export default function generatePicker<DateType extends object>(
  generateConfig: GenerateConfig<DateType>,
) {
  function getPicker<InnerPickerProps extends CustomPickerProps>(displayName?: string) {
    const Picker = React.forwardRef((props: InnerPickerProps, ref: React.Ref<typeof RCPicker>) => {
      const {
        prefixCls: customizePrefixCls,
        getPopupContainer: customizeGetPopupContainer,
        className,
        placeholder,
        size,
        isInvalid,
        ...restProps
      } = props;
      const { format, showTime, picker } = props as any;

      const additionalProps = {
        showNow: true,
      };

      let additionalOverrideProps: any = {};

      additionalOverrideProps = {
        ...additionalOverrideProps,
        ...(showTime ? getTimeProps({ format, picker, ...showTime }) : {}),
        ...(picker === 'time' ? getTimeProps({ format, picker }) : {}),
      };

      const prefixCls = 'date-picker';

      return (
        <RCPicker<DateType>
          ref={ref}
          placeholder={getPlaceholder(picker, enUS, placeholder)}
          prefixCls={prefixCls}
          suffixIcon={picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
          allowClear={<CloseCircleFilled />}
          {...additionalProps}
          {...restProps}
          {...additionalOverrideProps}
          locale={{
            ...enUS.lang,
            shortMonths: [
              t('value.jan'),
              t('value.feb'),
              t('value.mar'),
              t('value.apr'),
              t('value.may'),
              t('value.jun'),
              t('value.jul'),
              t('value.aug'),
              t('value.sep'),
              t('value.oct'),
              t('value.nov'),
              t('value.dec'),
            ],
            shortWeekDays: [
              t('value.su'),
              t('value.mo'),
              t('value.tu'),
              t('value.we'),
              t('value.th'),
              t('value.fr'),
              t('value.sa'),
            ],
            ok: t('button.ok'),
          }}
          generateConfig={generateConfig}
          components={Components}
          prevIcon={<span className={`${prefixCls}-prev-icon`} />}
          nextIcon={<span className={`${prefixCls}-next-icon`} />}
          superPrevIcon={<span className={`${prefixCls}-super-prev-icon`} />}
          superNextIcon={<span className={`${prefixCls}-super-next-icon`} />}
          className={classNames(
            { 'size-sm': size === 'sm', 'size-md': size === 'md' },
            { invalid: isInvalid },
            className,
          )}
        />
      );
    });

    if (displayName) {
      Picker.displayName = displayName;
    }

    return Picker;
  }

  const DatePicker = getPicker();
  const WeekPicker = getPicker('WeekPicker');
  const MonthPicker = getPicker('MonthPicker');
  const YearPicker = getPicker('YearPicker');
  const TimePicker = getPicker('TimePicker');
  const QuarterPicker = getPicker('QuarterPicker');

  return {
    DatePicker,
    WeekPicker,
    MonthPicker,
    YearPicker,
    TimePicker,
    QuarterPicker,
  };
}

import { FC } from 'react';

interface ButtonProp {
  bgColor: string;
  textColor: string;
  onClick: Function;
  children: any;
  disabled?: boolean;
  style?: any;
}

const Button: FC<ButtonProp> = ({
  bgColor,
  textColor,
  onClick,
  children,
  disabled = false,
  style,
}) => {
  return (
    <button
      style={style}
      disabled={disabled}
      className={`flex px-4 py-3 rounded-md border-[1px] lg:border-0 shadow-none lg:shadow-md align-center justify-center w-full ease-in duration-150 hover:shadow-slate-500 ${disabled ? 'bg-vGray' : bgColor} ${textColor}`}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};

export default Button;

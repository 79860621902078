import { isSafari } from 'react-device-detect';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';
import { zIndices } from 'themes/foundations/z-index';

const separateVariant = (props: any) => {
  const size: 'sm' | 'md' = props.size;
  const fontSizesTh = {
    md: createResponsiveStyle({ base: 'sm', lg: 'sm-md', '2xl': 'md' }),
    sm: 'sm',
  };
  return {
    table: {
      borderSpacing: '0 8px',
      borderCollapse: 'separate',
      '&.expandable': {
        borderSpacing: '0 4px',
      },
      '&.small-spacing': {
        tbody: {
          tr: {
            td: {
              p: '8px 12px',
              '&.no-padding': {
                p: '0px',
              },
            },
          },
        },
      },
    },
    th: {
      padding: '0',
      lineHeight: 1.2,
    },
    td: {},
    caption: {},
    thead: {
      tr: {
        // boxShadow: `0 2px 3px ${colors.tangoOpacity[30]}`, // Removed this to fix the Table component in /components/organisms/Table
        borderRadius: '8px',
        th: {
          bg: 'primary',
          color: 'white',
          fontFamily: 'body',
          fontWeight: 'normal',
          textTransform: 'initial',
          letterSpacing: 'wider',
          textAlign: 'center',
          fontSize: fontSizesTh[size],
          pr: '2px',
          '&:not(:first-of-type):before': {
            content: '""',
            height: 'calc(100% - 12px)',
            background: 'white',
            position: 'absolute',
            left: '-1.5px',
            top: '6px',
            border: '1px solid white',
          },
          '&:first-of-type': {
            borderStartRadius: '8px',
          },
          '&:last-of-type': {
            borderEndRadius: '8px',
          },
        },
      },
    },
    tbody: {
      tr: {
        bg: isSafari ? 'white' : undefined,
        overflow: 'hidden',
        borderRadius: '14px',
        position: 'relative',
        '& > td': {
          borderBottom: isSafari ? `1px solid #f05e144d` : undefined,
        },
        '&.disable': {
          td: {
            bg: 'disable',
            '&:first-of-type': {
              borderStartRadius: '14px',
            },
            '&.fixed-column': {
              position: 'sticky',
              zIndex: 5,
            },
          },
        },

        '&.row-edit': {
          td: {
            '&:first-of-type': {
              bg: colors.vulcan[200],
            },
          },
        },
        '&.row-active': {
          td: {
            bg: 'tango.300',
            color: 'white',
          },
        },
        '&.expand-row': {
          td: {
            '&:first-of-type': {
              '&::before': {
                content: 'none',
              },
            },
          },
        },

        td: {
          textTransform: 'initial',
          letterSpacing: 'wider',
          position: 'relative',
          '&.fixed-column': {
            position: 'sticky',
            zIndex: 5,
          },
          '&:not(:first-of-type):before': {
            content: '""',
            height: 'calc(100% - 12px)',
            width: '1px',
            background: colors.vulcanOpacity[20],
            position: 'absolute',
            left: '-1.5px',
            top: '6px',
          },
          '&:first-of-type': {
            borderStartRadius: '14px',
            // ...blurRowStyle,
            '& > *': {
              position: 'relative',
              zIndex: 2,
            },
          },
          '&:last-of-type': {
            borderEndRadius: '14px',
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const feezeHeaderAndFirstColVariant = (props: any) => {
  const size: 'sm' | 'md' = props.size;

  const fontSizesTh = {
    md: createResponsiveStyle({ base: 'sm', lg: 'sm-md', '2xl': 'md' }),
    sm: 'sm',
  };
  return {
    table: {
      borderSpacing: '0 8px',
      borderCollapse: 'separate',
    },
    th: {
      padding: '0',
      lineHeight: 1.2,
    },
    td: {},
    caption: {},
    thead: {
      tr: {
        borderRadius: '8px',
        boxShadow: `0 2px 3px ${colors.tangoOpacity[30]}`,
        th: {
          bg: 'primary',
          color: 'white',
          fontFamily: 'body',
          fontWeight: 'normal',
          textTransform: 'initial',
          letterSpacing: 'wider',
          textAlign: 'center',
          fontSize: fontSizesTh[size],
          pr: '2px',
          position: 'sticky',
          top: '0',
          zIndex: 'docked',
          _first: {
            zIndex: zIndices.docked + 1,
            left: 0,
          },
          '&:after': {
            content: '""',
            height: 'calc(100% - 12px)',
            width: '2px',
            background: 'white',
            position: 'absolute',
            right: '1px',
            top: '6px',
          },
          '&:first-of-type': {
            borderStartRadius: '8px',
          },
          '&:last-of-type': {
            borderEndRadius: '8px',

            '&:after': {
              content: 'unset',
            },
          },
        },
      },
    },
    tbody: {
      tr: {
        boxShadow: `0 3px 3px ${colors.tangoOpacity[10]}`,
        overflow: 'hidden',
        borderRadius: '14px',
        position: 'relative',

        td: {
          bg: 'white',
          textTransform: 'initial',
          letterSpacing: 'wider',
          position: 'relative',
          _first: {
            zIndex: 'docked',
            left: 0,
            position: 'sticky',
          },
          '&.highlight': {
            bg: 'tango.200',
          },
          '&.no-separator': {
            '&:after': {
              content: 'none',
            },
          },
          '&:after': {
            content: '""',
            height: 'calc(100% - 12px)',
            width: '1px',
            background: colors.tangoOpacity[20],
            position: 'absolute',
            right: '-1.5px',
            top: '6px',
            zIndex: 2,
          },
          '&:first-of-type': {
            borderStartRadius: '14px',
          },
          '&:last-of-type': {
            borderEndRadius: '14px',
            '&:after': {
              content: 'none',
            },
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variants = {
  separate: separateVariant,
  feezeHeaderAndFirstCol: feezeHeaderAndFirstColVariant,
};

export const Table = {
  variants,
  defaultProps: {
    variant: 'separate',
    size: 'md',
    colorScheme: 'tango',
  },
};

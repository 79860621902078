import { Box, BoxProps, Center, HStack, Icon, VStack, useBreakpointValue } from '@chakra-ui/react';
import noop from 'lodash/noop';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeviceMode } from 'types';
import useResizeObserver from 'use-resize-observer';

import { foundations } from 'themes/foundations';
import { createResponsiveStyle } from 'themes/foundations/breakpoints';

import { Typography } from 'components/atoms';

import { useDeviceMode, useRouter, useScreenMode, useTranslate } from 'hooks/common';
import { useMainLayout } from 'hooks/components/templates';

// import { LAYOUT_CONFIG } from 'configs/layout';

import { convertRemToPixels } from 'utils/unit';

import { SubMenu } from './SubMenu';
import { MenuConfig } from './nav-config';

export type NavMenuProps = {
  menu: MenuConfig[];
  onClickChange?: () => void;
};

export const NavMenu: React.FC<NavMenuProps> = ({ onClickChange = noop, ...props }) => {
  const { pathname } = useRouter();
  const t = useTranslate();
  const screen = useScreenMode();
  const lightHeight = 1.5;
  const fontSize = useBreakpointValue(
    createResponsiveStyle({
      base: foundations.fontSizes['lg'],
      lg: foundations.fontSizes['sm-lg'],
      lxl: foundations.fontSizes['sm-lg'],
      '2xl': foundations.fontSizes['lg'],
    }),
    screen,
  );
  const fontSizeInteger = convertRemToPixels(fontSize || foundations.fontSizes['lg']);
  // const marginTop = 16;

  const subMenuItemHeight: number = useBreakpointValue(
    createResponsiveStyle({
      base: 37,
      lg: 31,
      lxl: 31,
      '2xl': 37,
    }),
    screen,
  );

  const mainLayout = useMainLayout();
  const device = useDeviceMode();

  const [activePath, setActivePath] = useState('');
  const [activeParentPath, setActiveParentPath] = useState('');
  const [displayedSubMenu, setDisplayedSubMenu] = useState('');

  const { ref, width: wrapperWidth = 0 } = useResizeObserver<HTMLDivElement>();

  const checkPathActive = (path: string) => {
    if (path === displayedSubMenu && activeParentPath !== path) return false;
    return [activePath, activeParentPath, displayedSubMenu].includes(path);
  };

  // const activeIndex = props.menu.findIndex((item) => checkPathActive(item.path));

  const handleNavigate = (path: string, parentPath?: string) => () => {
    setActivePath(path);
    setDisplayedSubMenu('');
    setActiveParentPath(parentPath ?? '');
    onClickChange();
  };

  const handleOpenSubMenu = (path: string) => () => {
    setDisplayedSubMenu((prev) => {
      if (prev === path) return '';
      return path;
    });
  };
  const calcHeightOfSubMenu = (count: number) => 20 + count * (subMenuItemHeight + 8) - 8; // py + height of all item with mt  - first mt

  // const calcYPosition = (index: number) => {
  //   const rowHeight = fontSizeInteger * lightHeight + marginTop;
  //   let currentItemYPosition = (index + 1) * rowHeight;
  //   if (displayedSubMenu && device === DeviceMode.Mobile) {
  //     const parentIndex = props.menu.findIndex(
  //       (item) => item.path === displayedSubMenu && item.subMenu?.length,
  //     );
  //     if (parentIndex !== -1 && activeIndex > parentIndex) {
  //       const parent = props.menu[parentIndex];
  //       if (parent?.subMenu?.length) {
  //         currentItemYPosition += calcHeightOfSubMenu(parent.subMenu.length) + 15;
  //       }
  //     }
  //   }
  //   return currentItemYPosition - rowHeight;
  // };

  function splitPath(pathname: string): [string, string[]] {
    const splitPath = pathname.split('/');
    splitPath.shift();
    return [`/${splitPath.slice(0, 1)}`, splitPath];
  }

  // detect default path
  useEffect(() => {
    if (pathname === activePath) return;
    const [rootPath, paths] = splitPath(pathname);
    if (paths.length > 1) {
      setActiveParentPath(rootPath);
      setActivePath(pathname);
    } else {
      const activeItem = props.menu.find((item) => rootPath === item.path);
      setActivePath(activeItem?.path ?? pathname);
      setActiveParentPath('');
    }
    setDisplayedSubMenu('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, props.menu]);

  useEffect(() => {
    if (mainLayout.visibleMenu === false) {
      setDisplayedSubMenu('');
    }
  }, [mainLayout.visibleMenu]);

  const hover: BoxProps['_hover'] =
    device === DeviceMode.Desktop ? { opacity: 1, fontWeight: 'bold' } : undefined;

  return (
    <VStack
      className="NavMenu"
      alignItems="flex-start"
      spacing="16px"
      position="relative"
      ref={ref}
    >
      {props.menu.map(({ title, icon: NavIcon, path, subMenu, titleInMobile }, index) => {
        const [fistSubMenuItem] = subMenu || [];
        const isActive = checkPathActive(path);
        const isReplaceParent = false;
        const shouldRenderSubMenu = !isReplaceParent && subMenu?.length;
        const isShowSubMenu = displayedSubMenu === path;
        const activeStyles: BoxProps | undefined =
          isActive || isShowSubMenu
            ? {
                fontWeight: 'bold',
                opacity: 1,
                _hover: undefined,
              }
            : undefined;
        const navigatePath = isReplaceParent ? fistSubMenuItem.path : path;
        const MenuLink = shouldRenderSubMenu ? Fragment : Link;
        const menuLinkProps: any = MenuLink === Link ? { to: navigatePath } : undefined;
        const parentTitle = device === DeviceMode.Mobile ? titleInMobile ?? title : title;
        const displayTitle = t(isReplaceParent ? fistSubMenuItem.title : parentTitle);

        return (
          <Box w="100%" key={index} position="relative" zIndex="docked">
            <MenuLink {...menuLinkProps}>
              <HStack
                _hover={hover}
                spacing="4"
                onClick={
                  shouldRenderSubMenu
                    ? handleOpenSubMenu(path)
                    : handleNavigate(navigatePath, isReplaceParent ? path : undefined)
                }
                cursor="pointer"
                opacity={createResponsiveStyle({ base: 1, lg: 0.5 })}
                transition="all 200ms  ease"
                zIndex="docked"
                // className={isShowSubMenu ? 'active' : undefined}
                {...activeStyles}
              >
                <Center w="24px">
                  <Icon
                    as={NavIcon}
                    w="24px"
                    h="auto"
                    opacity={createResponsiveStyle({
                      base: activeStyles?.opacity ?? 0.5,
                      lg: 'inherit',
                    })}
                  />
                </Center>
                <Typography.Text
                  title={displayTitle}
                  fontSize={createResponsiveStyle({ base: 'lg', lg: 'sm-lg', '2xl': 'lg' })}
                  whiteSpace="nowrap"
                  isTruncated
                >
                  {displayTitle}
                </Typography.Text>
              </HStack>
            </MenuLink>
            {!!subMenu?.length && !isReplaceParent && (
              <SubMenu
                subMenu={subMenu}
                parentPath={path}
                show={isShowSubMenu}
                height={`${calcHeightOfSubMenu(subMenu.length)}px`}
                wrapperWidth={wrapperWidth}
                itemHeight={fontSizeInteger * lightHeight}
                activePath={activePath}
                onNavigate={handleNavigate}
              />
            )}
          </Box>
        );
      })}

      {/* <Box
        w={`calc(100% + ${LAYOUT_CONFIG.gap})`}
        h={`${fontSizeInteger * lightHeight}px`}
        borderBottom="1px solid"
        borderBottomColor="vulcanOpacity.30"
        zIndex="base"
        visibility={hasActive ? 'visible' : 'hidden'}
        position="absolute"
        top={`-${marginTop}px`}
        transition="transform 200ms  ease"
        transform={`translateY(${calcYPosition(activeIndex)}px)`}
      >
        <Box h="100%" position="absolute" right="0" w="6px" backgroundColor="tango.600" />
      </Box> */}
    </VStack>
  );
};

export * from './nav-config';

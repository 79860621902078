import { FC } from 'react';

interface TextAreaProp {
  value: string;
  onChange: Function;
  edit: boolean;
  template: boolean;
  placeholder: string;
  rows: number;
  errors?: any;
}

const TextArea: FC<TextAreaProp> = ({
  value,
  onChange,
  edit,
  template,
  placeholder,
  rows,
  errors,
}) => {
  return (
    <div className="flex flex-col w-full space-y-1">
      <textarea
        className={`flex w-full border-[1px] px-3 py-2 ease-in duration-150 rounded-md border-vGrayWhite shadow-none lg:shadow-sm outline-transparent focus:border-vOrange ${(!edit || template) && 'bg-vGrayWhite pointer-events-none'}`}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
      />
      {errors && <p className="text-red-500 text-xs">{errors}</p>}
    </div>
  );
};

export default TextArea;

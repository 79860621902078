import { FC, useState } from 'react';
import { Box, HStack, Text, Collapse, IconButton } from '@chakra-ui/react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6';

interface ExpandableDetailsProps {
  title: string;
  detail: string;
}

const ExpandableDetails: FC<ExpandableDetailsProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleExpand = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HStack w="full" alignItems="start" justifyContent="space-between" p={2}>
        <Text fontWeight="bold" fontSize="20">{props.title}</Text>
        <IconButton
          aria-label={isOpen ? 'Collapse' : 'Expand'}
          icon={isOpen ? <FaChevronUp color="#999999"/> : <FaChevronDown color="#999999"/>}
          onClick={toggleExpand}
          variant="link"
        />
      </HStack>
      <Collapse in={isOpen} >
        <Box w="full" textAlign="start">
          <Text mt={2} alignSelf="flex-start">
            {props.detail}
          </Text>
        </Box>
      </Collapse>
    </>
  );
};

export default ExpandableDetails;
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { currentUserFeatureSelector } from 'store/modules/auth';

import { SystemFeatures } from 'pro/types/features';

export const useCurrentFeature = () => {
  const currentFeatures = useSelector(currentUserFeatureSelector);

  const checkHasFeatures = useCallback(
    (features?: SystemFeatures | SystemFeatures[], strategy?: 'some' | 'every'): boolean => {
      if (!features) return true;
      if (!currentFeatures?.length) return false;
      if (features instanceof Array) {
        return currentFeatures[strategy ?? 'some']((item) => features.includes(item));
      }

      return currentFeatures.includes(features);
    },
    [currentFeatures],
  );

  return useMemo<[typeof currentFeatures, typeof checkHasFeatures]>(
    () => [currentFeatures, checkHasFeatures],
    [checkHasFeatures, currentFeatures],
  );
};
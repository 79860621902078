import { FC, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import IconButton from './IconButton';

interface DropDownProp {
  header: any,
  content: Array<any>,
};

const DropDown: FC<DropDownProp> = ({header, content}) => {

  const [isShow, setIsShow] = useState<boolean>(true);
  
  return (
    <div className='flex flex-col space-y-1'>
      {/* control display of dropdown */}
      <div className='flex justify-between align-center'>
        <p className='flex w-full font-bold'>{header.name}</p>
        {(header?.start && header?.end) &&
          <div className='flex w-full space-x-1'>
            <p className='text-[10px]'>Start</p>
            <p>12:00 PM</p>
            <p className='text-[10px]'>End</p>
            <p>2:00 PM</p>
          </div>
        }
        <IconButton
          textColor='text-vGray'
          onClick={() => setIsShow((curr: boolean) => !curr)}
        >
          {isShow ?  <FaAngleUp/> : <FaAngleDown/>}
        </IconButton>
      </div>
      <div className={`p-4 bg-vGrayWhite rounded-md shadow-sm ${isShow ? 'flex' : 'hidden'}`}>
        {/* dropdown value */}
        <div className='flex flex-col w-full space-y-4'>
          {content.length > 0 && content.map((val: any, i: any) => (
            <div key={i} className='flex flex-col w-full space-y-1 text-xs'>
              {val?.title && <p className='font-bold'>{val.title}</p>}
              {val?.body && <p className='whitespace-pre-line'>{val.body}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
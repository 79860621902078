export interface newManager {
  first_name: string;
  middle_name: string;
  last_name: string;
  role: number;
  locations: Array<number>;
  contact_number: string;
  email: string;
  company: number;
  avatar: File | null;
}

export const ManagerInterface: newManager = {
  first_name: '',
  middle_name: '',
  last_name: '',
  role: 0,
  locations: [],
  contact_number: '',
  email: '',
  company: 1,
  avatar: null,
};

export interface newWorker {
  contact_number: string;
  email: string;
  locations: number;
  avatar: File | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: number;
  date_of_birth: string;
  region: string;
  city: string;
  district: string;
  postal_code: string;
  line: string;
  government_id_type: string;
  government_id_number: string;
  government_id_front: File | null;
  government_id_back: File | null;
  bank: string;
  bank_branch: string;
  account_name: string;
  account_number: string;
  bank_code: string;
  emergency_contact_name: string;
  emergency_contact_relationship: string;
  emergency_contact_number: string;
  employee_benefits_worker_type: string;
  employee_benefits_salary_rate_type: string;
  employee_benefits_annual_leave: string;
  employee_benefits_sick_leave: string;
  employee_benefits_parental_leave: string;
  employee_benefits_marital_leave: string;
  employee_benefits_bereavement_leave: string;
  employee_benefits_other_leave: string;
  employee_benefits_other_leave_value: string;
  employee_benefits_other_leave_temp: string;
  employee_benefits_gross_basic_pay: string;
  employee_benefits_maximum_working_hours_per_week: string;
  employee_benefits_monthly_working_hours: string;
  employee_benefits_working_days: string;
  employee_benefits_thirteenth_month_bonus: number;
  employee_benefits_hdmf_loan : string;
  employee_benefits_sss_loan: string;
  employee_benefits_clothing_allowance: string;
  employee_benefits_laundry_allowance: string;
  employee_benefits_rice_subsidy: string;
  employee_benefits_dependents_medical_allowance: string;
  employee_benefits_meal_allowance: string;
  employee_benefits_productivity_incentive_schemes: string;
  identity_documents_police_clearance: File | null;
  identity_documents_nbi_clearance: File | null;
  identity_documents_bir_2303: File | null;
  identity_documents_valid_id: File | null;
  identity_documents_other_document: File | null;
}

export const WorkerInterface: newWorker = {
  contact_number: '',
  email: '',
  locations: 0,
  avatar: null,
  first_name: '',
  middle_name: '',
  last_name: '',
  gender: 0,
  date_of_birth: '',
  region: '',
  city: '',
  district: '',
  postal_code: '',
  line: '',
  government_id_type: '',
  government_id_number: '',
  government_id_front: null,
  government_id_back: null,
  bank: '',
  bank_branch: '',
  account_name: '',
  account_number: '',
  bank_code: '',
  emergency_contact_name: '',
  emergency_contact_relationship: '',
  emergency_contact_number: '',
  employee_benefits_worker_type: '',
  employee_benefits_salary_rate_type: 'daily_rate',
  employee_benefits_annual_leave: '',
  employee_benefits_sick_leave: '',
  employee_benefits_parental_leave: '',
  employee_benefits_marital_leave: '',
  employee_benefits_bereavement_leave: '',
  employee_benefits_other_leave: '',
  employee_benefits_other_leave_value: '',
  employee_benefits_other_leave_temp: '',
  employee_benefits_gross_basic_pay: '',
  employee_benefits_maximum_working_hours_per_week: '',
  employee_benefits_monthly_working_hours: '',
  employee_benefits_working_days: '',
  employee_benefits_thirteenth_month_bonus: 0,
  employee_benefits_hdmf_loan: '',
  employee_benefits_sss_loan: '',
  employee_benefits_clothing_allowance: '',
  employee_benefits_laundry_allowance: '',
  employee_benefits_rice_subsidy: '',
  employee_benefits_dependents_medical_allowance: '',
  employee_benefits_meal_allowance: '',
  employee_benefits_productivity_incentive_schemes: '',
  identity_documents_police_clearance: null,
  identity_documents_nbi_clearance: null,
  identity_documents_bir_2303: null,
  identity_documents_valid_id: null,
  identity_documents_other_document: null,
};

export interface ManagerFilterTemplate {
  recent_added: boolean;
  manager_status: string;
  manager_type: string;
  company_id: string;
  outlet_id: number|null;
}

export const ManagerFilterTemplate: ManagerFilterTemplate = {
  recent_added: false,
  manager_status: '',
  manager_type: '',
  company_id: '',
  outlet_id: null,
};

export interface WorkerFilterTemplate {
  recent_added: boolean;
  worker_status: string;
  identity_status: string;
  min_rating: number;
  max_rating: number;
  applicant_job_amount: number;
  outlet_id: number|null;
  rate: string;
}

export const WorkerFilterTemplate: WorkerFilterTemplate = {
  recent_added: false,
  worker_status: '',
  identity_status: '',
  min_rating: 0,
  max_rating: 0,
  applicant_job_amount: 0,
  outlet_id: null,
  rate: ''
};

import axios from 'axios';
import { config } from '../config/config';
import { authStorage } from 'configs/browser-storage';
import { UserLeave, UserLeaveResponse } from 'models/user'; 

export const ShowAllUserLeaves = async (): Promise<UserLeave[]> => {
  try {
    const response = await axios.get<UserLeaveResponse>(`${config.API_URL}/leave-management/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },
    });

    return response.data.results;
  } catch (err) {
    throw err;
  }
}

export const ApproveRejectLeaves = async (id: number, status: any) => {
  try {
    const response = await axios.patch<UserLeaveResponse>(`${config.API_URL}/leave-management/${id}/approve-reject/`, status, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },
    });

    return response.data.results; // Return the results array
  } catch (err) {
    throw err;
  }
}

export const approveRejectLeave = async (
  leaveId: number,
  status: string,
  managerFeedback?: string
): Promise<any> => {
  try {
    const response = await axios.patch(
      `${config.API_URL}/leave-management/${leaveId}/approve-reject/`,
      {
        status,
        manager_feedback: managerFeedback,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};
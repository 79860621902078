import { FC, useEffect, useState } from 'react';
import { FaRegTrashCan, FaSpinner, FaXmark } from 'react-icons/fa6';
import ManagerForm from './ManagerForm';
import { UpdateManager, DeleteManager } from '../../api/workforce';
import { DeleteModal, FailModal, UnsaveModal, UpdateModal } from '../../components/Modal';
import SidePop from 'pro/components/SidePop';
import IconButton from 'pro/components/IconButton';
import Button from 'pro/components/Button';
import { requiredFields } from 'pro/forms/workforce/CreateManager';
import { currentRegionsSelector, currentManagersSelector } from 'store/modules/auth/selector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/root-reducer';
import { keptQueryActions } from 'store/modules/kept-query';
import { managerActions } from 'store/modules/manager';
interface EditManagerProp {
  visible: boolean;
  onClose: () => void;
  selectedManager: any;
}

const EditManager: FC<EditManagerProp> = ({ visible, onClose, selectedManager }) => {
  const dispatch = useDispatch();
  const [manager, setManager] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [openFail, setOpenFail] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openUnsave, setOpenUnsave] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [show, setShow] = useState<boolean>(visible);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [apiError, setApiError] = useState<any>('');
  const stored_managers = useSelector(currentManagersSelector);
  useEffect(() => {
    if (visible) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), 300); // Delay to match the animation duration
    }
  }, [visible]);

  useEffect(() => {
    if (!!selectedManager) {
      setManager({
        ...selectedManager,
        // contact_number: String(selectedManager.contact_number).slice(3),
      });
    }
  }, [selectedManager]);

  // validate input value if none or 0
  // const validate = () => {
  //   let valid: boolean = false;
  //   if (
  //     (manager?.first_name &&
  //       manager?.last_name &&
  //       manager?.middle_name &&
  //       manager?.contact_number &&
  //       manager?.email) !== '' &&
  //     (manager?.role) !== 0 &&
  //     (manager?.locations.length) > 0
  //   ) {
  //     valid = true;
  //   }
  //   return valid;
  // };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const validate = () => {
    let valid = true;
    const newErrors: Record<string, string> = {};

    requiredFields.forEach((field) => {
      if (!manager[field] || (Array.isArray(manager[field]) && manager[field].length === 0)) {
        valid = false;
        newErrors[field] = `${capitalizeWords(field.replace('_', ' '))} is required.`;
      }
      if (field === 'contact_number' && (manager[field].length < 9 || manager[field].length > 10)) {
        valid = false;
        newErrors[field] = `${capitalizeWords(field.replace('_', ' '))} must be a 9 to 10 digit number.`;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  // submit edited manager
  const handleSubmit = async () => {
    try {
      setSaving(true);
      const newManager = await UpdateManager(manager);
      setOpenSuccess(true);
      setSaving(false);
      if (stored_managers && stored_managers.length > 0 && newManager) {
        const updatedManagers = stored_managers.map((manager) =>
          manager.id === newManager.id ? newManager : manager,
        );
        if (updatedManagers && updatedManagers.length > 0) {
          dispatch(managerActions.updateManagers(updatedManagers));
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorDetails = error.response.data.detail;
        if (typeof errorDetails === 'string') {
          setApiError(errorDetails);
        } else if (Array.isArray(errorDetails)) {
          setApiError(errorDetails.join(', '));
        } else if (typeof errorDetails === 'object' && errorDetails !== null) {
          const formattedErrors = Object.entries(errorDetails).map(
            ([field, reason]) => `${reason}`,
          );
          setApiError(formattedErrors);
        }
      }
      setSaving(false);
      setOpenFail(true);
    }
  };

  // open modal to confirm delete manager
  const confirmDelete = (id: number) => {
    setOpenDelete(true);
    setDeleteId(id);
  };

  const removeManagerById = async () => {
    try {
      await DeleteManager(deleteId);
      setOpenDeleteSuccess(true);
      if (stored_managers && stored_managers.length > 0) {
        const updatedManagers = stored_managers.filter((manager) => manager.id !== deleteId);
        dispatch(managerActions.updateManagers(updatedManagers));
      }
    } catch (error) { }
  };

  return (
    <SidePop zIndex="z-10">
      <div className="flex flex-col bg-vWhite w-full lg:w-[500px] justify-between">
        <div className="flex flex-col w-full p-8 h-full overflow-auto">
          <div className="flex justify-between align-center text-lg font-bold pb-6">
            <p>
              {manager?.display_name} {manager?.id}
            </p>
            <div className="flex space-x-2">
              <IconButton textColor="text-vGray" onClick={() => confirmDelete(manager?.id)}>
                <FaRegTrashCan />
              </IconButton>
              <IconButton
                textColor="text-vGray"
                onClick={() => (edit ? setOpenUnsave((curr: boolean) => !curr) : onClose())}
              >
                <FaXmark />
              </IconButton>
            </div>
          </div>
          <div>
            <ManagerForm manager={manager} setManager={setManager} edit={edit} errors={errors} />
          </div>
        </div>
        <div className="flex px-8 py-4 shadow-inner mt-2 space-x-4 lg:space-x-0">
          <div className="lg:hidden flex w-full">
            <Button bgColor="bg-vWhite" textColor="text-vPurple" onClick={() => onClose()}>
              <p>Cancel</p>
            </Button>
          </div>
          <div className="flex w-full">
            {edit ? (
              <Button
                bgColor="bg-vOrange"
                textColor="text-vWhite"
                onClick={() => validate() && handleSubmit()}
              >
                {!saving ? 'Save' : <FaSpinner className="flex text-xl animate-spin" />}
              </Button>
            ) : (
              <Button
                bgColor="bg-vWhite"
                textColor="text-vPurple"
                onClick={() => setEdit((curr: boolean) => !curr)}
              >
                <p>Edit Manager</p>
              </Button>
            )}
          </div>
        </div>
      </div>
      {openSuccess && (
        <UpdateModal
          confirm={onClose}
          title="Changes Saved!"
          message={`The changes you did for the Manager ${manager?.name} are now saved.`}
        />
      )}
      {openFail && (
        <FailModal
          confirm={() => {
            setOpenFail((curr: boolean) => !curr);
            if (!apiError) handleSubmit();
          }}
          cancel={() => setOpenFail((curr: boolean) => !curr)}
          title="Uh-oh!"
          message={
            apiError
              ? apiError +
              ' ' +
              'It seems there was a hiccup while saving. Let`s double-check the details and try again to ensure everything runs smoothly. Do try again!'
              : 'It seems there was a hiccup while saving. Let`s double-check the details and try again to ensure everything runs smoothly. Do try again!'
          }
        />
      )}
      {openUnsave && (
        <UnsaveModal
          confirm={() => {
            onClose();
            handleSubmit();
          }}
          cancel={onClose}
          title="Save Changes"
          message="You are exiting edit mode without saving the changes. Do you want to save the changes made on the fields?"
        />
      )}
      {openDelete && (
        <DeleteModal
          confirm={() => {
            setOpenDelete((curr: boolean) => !curr);
            removeManagerById();
          }}
          cancel={() => setOpenDelete((curr: boolean) => !curr)}
          title="Delete Manager"
          message={`Are you sure you want to delete Manager “${manager?.name}”? Please agree with the checkbox to proceed on before deleting the Manager on the database.`}
          warning="I confirm that I would like to delete the Manager"
        />
      )}
      {openDeleteSuccess && (
        <UpdateModal
          confirm={() => {
            onClose();
            setOpenDeleteSuccess((curr: boolean) => !curr);
          }}
          title="Manager Removed!"
          message={`Manager “${manager?.name}” is now deleted on the database.`}
        />
      )}
    </SidePop>
  );
};

export default EditManager;

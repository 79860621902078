import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IUser } from 'models/user';

import { getCurrentUserPermission } from './action';
import { AuthState } from './type';

const initialState: AuthState = {
  isAuthenticated: false,
  init: true,
  isRemember: null,
  isExpired: false,
  permissions: [], // gig 
  features: [], // shared
  permission_pro: [], // pro
};
const { actions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authen(state, action: PayloadAction<Pick<Required<AuthState>, 'user' | 'accessToken'>>) {
      const { payload } = action;
      state.init = false;
      state.isAuthenticated = true;
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.isExpired = false;

      state.permissions = payload.user.permissions;
      state.features = payload.user.features;
      state.permission_pro = payload.user.permissions;
    },
    checkRemember(state, action: PayloadAction<boolean>) {
      state.isRemember = action.payload;
    },
    authWithFailed(state) {
      state.init = false;
    },
    updateUser(state, action: PayloadAction<Partial<IUser>>) {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    expireSession(state) {
      if (state.isAuthenticated) {
        state.isExpired = true;
      }
    },
    clear() {
      return { ...initialState, init: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserPermission.fulfilled, (state, action) => {
      const user = action.payload;
      state.user = user;
      state.permissions = user.permissions;
      state.features = user.features;
      state.permission_pro = user.permissions;
    });

    builder.addCase(getCurrentUserPermission.rejected, (state) => {
      state.permissions = [];
      state.features = [];
      state.permission_pro = [];
    });
  },
});

const authActions = { ...actions, getCurrentUserPermission };
export { authReducer, authActions };

export * from './selector';
export * from './type';

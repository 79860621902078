import { Box, BoxProps, Divider, HStack, Icon, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceMode } from 'types';

import { authActions } from 'store/modules/auth';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';

import { authApi } from 'apis/auth';

import { Typography } from 'components/atoms';
import { ProfileInfo } from 'components/molecules';
import { CollapsableProfileInfo } from 'components/molecules/CollapsableProfileInfo';

import { useDeviceMode, useRouter, useTranslate } from 'hooks/common';

import { LogoIcon, LogoutIcon, TextLogoIcon } from 'assets/icons';

import { authStorage, rememberMeStorage, hqStorage, userStorage } from 'configs/browser-storage';
import { LAYOUT_CONFIG } from 'configs/layout';
import { AppDispatch } from 'store/root-reducer';
import { NavMenu, navMenuConfig } from './NavMenu';
import { useAuthorizedMenu } from './NavMenu/useAuthorizedMenu';

export type SideBarProps = {
  onChangeMenu?: () => void;
} & BoxProps;
export const SideBar: FC<SideBarProps> = ({ onChangeMenu = noop, ...props }) => {
  const device = useDeviceMode();
  const router = useRouter();
  const t = useTranslate();
  const dispatch = useDispatch<AppDispatch>();

  const menu = useAuthorizedMenu(navMenuConfig);

  const handleLogout = () => {
    authApi.logout();
    authStorage.clear();
    userStorage.clear();
    hqStorage.clear();
    rememberMeStorage.clear();
    dispatch(authActions.clear());
  };

  const handleClickLogo = () => {
    onChangeMenu();
    router.navigate(PATH_NAME.DASHBOARD);
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ overflowY: 'hidden', overflowX: 'hidden' }}
      px={createResponsiveStyle({ base: '36px', md: LAYOUT_CONFIG.gap, lg: 'initial' })}
      // className="SideBar"
      {...props}
    >
      {/* {device === DeviceMode.Mobile && (
        <VStack spacing="14px">
          <Icon w="60px" height="auto" as={LogoIcon} onClick={handleClickLogo} />
          <Icon w="170px" height="auto" as={TextLogoIcon} onClick={handleClickLogo} />
        </VStack>
      )}
      {device === DeviceMode.Tablet && (
        <HStack spacing="14px" justifyContent="center">
          <Icon w="45px" height="auto" as={LogoIcon} onClick={handleClickLogo} />
          <Icon w="170px" height="auto" as={TextLogoIcon} onClick={handleClickLogo} />
        </HStack>
      )} */}
      <Box mt="32px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="24px" w="300px">
        <Icon mb="30px" w="auto" height="auto" as={LogoIcon} onClick={handleClickLogo} />
        <NavMenu menu={menu} onClickChange={onChangeMenu} />
        <HStack mt="30px" mb="100px" spacing="4" cursor="pointer">
          <Icon as={LogoutIcon} w="24px" h="24px" />
          <Typography.Text
            fontSize={createResponsiveStyle({ base: 'lg', lg: '18px' })}
            onClick={handleLogout}
            className="text-vGray"
          >
            {t('button.logOut')}
          </Typography.Text>
        </HStack>
      </Box>
    </Box>
  );
};

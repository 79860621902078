import { FC } from 'react';

interface SidePopProp {
  children: any,
  zIndex: string,
};

const SidePop: FC<SidePopProp> = ({children, zIndex}) => {

  return (
    <div className='flex w-full h-full'>
      <div className={`flex fixed bg-black bg-opacity-40 inset-x-0 inset-y-0 flex-row-reverse ${zIndex}`}>
        {children}
      </div>
    </div>
  );
};

export default SidePop;
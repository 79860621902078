import { FC } from 'react';
import ClockInOutItem from "./ClockInOutItem";

interface ClockInOutSectionProps {
  qrType: string;
  filteredData: any;
  refreshData: any;
  clockType: string;
}

const ClockInOutSection: FC<ClockInOutSectionProps> = ({ qrType, filteredData, refreshData, clockType }) => {
  return (
    <>
      {(filteredData.clock_in.length  > 0 || filteredData.clock_out.length > 0) && (
        <h1 className="py-1 px-2 font-bold">{qrType}</h1>
      )}
      {filteredData.clock_in.length > 0 && (
        <div>
          <h3 className="py-1 px-2 font-bold">Clock In</h3>
          {(clockType === 'Clock In' || clockType === 'All' || clockType === '') && filteredData.clock_in.map((group: any, index: any) => (
            group.items.length > 0 && (
              <div
                key={index}
                style={{ backgroundColor: '#EDF0F7' }}
                className="rounded-lg shadow-md my-4"
              >
                <h3 className="py-2 px-2 font-bold">{group.location || 'Unknown Location'}</h3>
                {group.items.map((item: any, itemIndex: any) => (
                  <ClockInOutItem
                    key={itemIndex}
                    index={itemIndex}
                    type="location"
                    data={item}
                    refresh={refreshData}
                  />
                ))}
              </div>
            )
          ))}
        </div>
      )}

      {filteredData.clock_out.length > 0 && (
        <div>
          <p className="py-2 px-2 font-bold">Clock Out</p>
          {(clockType === 'Clock Out' || clockType === 'All' || clockType === '') && filteredData.clock_out.map((group: any, index: any) => (
            group.items.length > 0 && (
              <div
                key={index}
                style={{ backgroundColor: '#EDF0F7' }}
                className="rounded-lg shadow-md my-4"
              >
                <h3 className="py-1 px-2 font-bold">{group.location || 'Unknown Location'}</h3>
                {group.items.map((item: any, itemIndex: any) => (
                  <ClockInOutItem
                    key={itemIndex}
                    index={itemIndex}
                    type="location"
                    data={item}
                    refresh={refreshData}
                  />
                ))}
              </div>
            )
          ))}
        </div>
      )}
    </>
  );
};

export default ClockInOutSection;

import { FC } from 'react';

interface TextInputProp {
  value: string;
  onChange: Function;
  edit: boolean;
  template: boolean;
  placeholder: string;
  errors?: any;
}

const TextInput: FC<TextInputProp> = ({ value, onChange, edit, template, placeholder, errors }) => {
  return (
    <div className="flex flex-col w-full space-y-1">
      <input
        className={`flex w-full border-[1px] px-3 py-2 ease-in duration-150 rounded-md border-vGrayWhite shadow-none lg:shadow-sm outline-transparent focus:border-vOrange ${(!edit || template) && 'bg-vGrayWhite pointer-events-none'}`}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        required
      />
      {errors && <p className="text-red-500 text-xs">{errors}</p>}
    </div>
  );
};

export default TextInput;

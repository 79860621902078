import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Company } from 'pages/Companies'
import { CompanyState } from './type';

const initialState: CompanyState = {
  company: {
    id: 0, // Replace with suitable default values or null if not applicable
    created_at: '',
    updated_at: '',
    deleted_at: null,
    name: '',
    address: '',
    company_logo_filename: null,
    banner_filename: null,
    contract_service_filename: null,
    pro: true,
    payroll: false,
    status: true,
    registration_no: '',
    min_jod_credit_limit: null,
    available_credits: null,
    created_by: null,
    updated_by: null,
    company_description: null,
    company_size: null,
    editable_fields: {},
    editable_fields_admin: {},
    // benefits_list: [],
    company_url: '',
    company_email: '',
    company_contact_number: '',
    hm_size: 0,
    worker_size: 0,
  },
  summary: {
    totalActive: 0,
    totalDisable: 0,
    total: 0,
    init: false,
  },
  options: null,
  hasHQOptions: null,
  banner: null,
};

export const { actions: companyActions, reducer: companyReducer } = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompany(state, action: PayloadAction<Company>) {
      state.company = action.payload;
    },
    updateSummary(state, action: PayloadAction<Omit<CompanyState['summary'], 'init'>>) {
      const { payload } = action;
      state.summary = {
        ...payload,
        init: false,
      };
    },
    updateBanner(state, action: PayloadAction<CompanyState['banner']>) {
      const { payload } = action;
      state.banner = payload;
    },
    setOptions(state, action: PayloadAction<CompanyState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
    setHasHQOptions(state, action: PayloadAction<CompanyState['options']>) {
      const { payload } = action;
      state.hasHQOptions = payload;
    },
    updateCompanyOptionAvailableCredits(
      state,
      action: PayloadAction<{ companyId: number; amount: number }>,
    ) {
      const { companyId, amount } = action.payload;
      const updateItem = state.options?.find((item) => item.value === companyId);
      if (updateItem) {
        updateItem.available_credits += amount;
      }
    },
  },
});

export * from './type';
export * from './selector';

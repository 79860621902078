import { FireWithBadgeIcon } from 'assets/icons';
import { useDeviceMode } from 'hooks/common';
import { FC, memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { headerTitleSelector } from 'store/modules/config';
import { DeviceMode } from 'types';
import CompanySelector from 'pro/components/CompanySelector';
import Notifications from './Notifications';
import SwapRequest from './Approvals';
import { FaBars } from 'react-icons/fa6';
import { useCurrentRole } from 'hooks/components/auth';
import { Role } from 'models/user';
import { currentUserSelector } from 'store/modules/auth';
import { Avatar, Icon as ChakraIcon } from '@chakra-ui/react';
import { UserIcon } from 'assets/icons';
import EditManager from 'pro/forms/workforce/EditManager';
interface HeaderProps {
  onClick: () => void;
}

const Header: FC<HeaderProps> = ({ onClick }) => {
  const device = useDeviceMode();
  const { headerTitle } = useSelector(headerTitleSelector);
  const isMobile = device === DeviceMode.Mobile;
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openApproval, setOpenApproval] = useState<boolean>(false);
  const currentUserRole = useCurrentRole();
  const [openProfile, setOpenProfile] = useState<boolean>(false);

  const user = useSelector(currentUserSelector);

  return (
    <>
      <div className="flex items-center justify-between w-full px-5 lg:px-8 lg:bg-[#f6f7fa] lg:py-5">
        {isMobile && <div style={{ paddingTop: '5px' }}></div>}
        <div className="flex items-center gap-1">
          <h3 className="text-sm font-bold uppercase text-black md:text-xl lg:text-2xl">
            {headerTitle}
          </h3>
        </div>

        <div className="flex items-center gap-1">
          <div className="flex lg">
            {!isMobile && currentUserRole === Role.Admin && <CompanySelector />}
          </div>
          <div
            className="flex border border-gray-300 p-[4px] md:p-2.5 rounded-[5px] md:rounded-lg md:bg-white cursor-pointer"
            onClick={() => setOpenProfile(!openProfile)}
          >
            <Avatar
              size="2xs"
              bg="white"
              color="tango.600"
              src={user?.avatar ? user?.avatar : user?.avatar_url ? user?.avatar_url : ''}
              icon={
                <ChakraIcon
                  as={UserIcon}
                  transition="all 0.2s ease-in"
                  width={{
                    md: '32px',
                    lg: '24px',
                    '2xl': '32px',
                  }}
                  height={{
                    md: '24px',
                    lg: '16px',
                    '2xl': '24px',
                  }}
                />
              }
            />
          </div>
          <div
            className={`flex items-center ${isMobile ? 'w-[28px] h-[28px]' : 'w-[42px] h-[42px]'}`}
          >
            <FireWithBadgeIcon
              className="cursor-pointer"
              onClick={() => setOpenApproval((curr: any) => !curr)}
            />
          </div>
          <div
            className="flex xl:hidden border border-gray-300 p-[6px] md:p-3 rounded-[5px] md:rounded-lg md:bg-white cursor-pointer"
            onClick={onClick}
          >
            <FaBars className="text-vGray" />
          </div>
        </div>

        {openNotification && <Notifications setOpenNotification={setOpenNotification} />}
        {openApproval && <SwapRequest setOpenApproval={setOpenApproval} />}
        {openProfile && (
          <EditManager
            visible={openProfile}
            onClose={() => setOpenProfile(false)}
            selectedManager={user}
          />
        )}
      </div>
      {isMobile && currentUserRole === Role.Admin && (
        <div className="flex items-center justify-between w-full px-5 bg-[#f6f7fa]">
          <CompanySelector />
        </div>
      )}
    </>
  );
};

export default memo(Header);

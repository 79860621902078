export enum Features {
  USER_MANAGEMENT = 1,
  ROLE_MANAGEMENT = 2,
  COMPANY_MANAGEMENT = 3,
  OUTLET_MANAGEMENT = 4,
  WORKFORCE_MANAGEMENT = 5,
  JOB_MANAGEMENT = 6,
  ATTENDANCE_MANAGEMENT = 7,
  TASK_MANAGEMENT = 8,
  REPORTING_AND_ANALYTICS = 9,
  PAYROLL_MANAGEMENT = 10,
  NOTIFICATION_AND_ALERTS = 11,
  SYSTEM_CONFIGURATION = 12,
}

export type SystemFeatures = Features;
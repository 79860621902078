import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Option } from 'types';
import { ManagerState } from './type';

const initialState: ManagerState = {
  managers: [],
  summary: {
    totalArea: 0,
    totalLocation: 0,
    init: false,
  },
  options: [],
  enabledAreaManagerOptions: [],
};

export const { actions: managerActions, reducer: managerReducer } = createSlice({
  name: 'location',
  initialState,
  reducers: {
    updateManagers(state, action: PayloadAction<ManagerState['managers']>) {
      state.managers = action.payload;
    },
    addNewManager(state, action: PayloadAction<ManagerState['managers']>) {
      state.managers.unshift(action.payload);
    },
    updateSummary(state, action: PayloadAction<Omit<ManagerState['summary'], 'init'>>) {
      const { payload } = action;

      state.summary = {
        ...payload,
        totalArea: payload.totalArea,
        totalLocation: payload.totalLocation,
        init: false,
      };
    },
    updateOption(state, action: PayloadAction<ManagerState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
    setEnabledAreaManagerOptions(
      state,
      action: PayloadAction<ManagerState['enabledAreaManagerOptions']>,
    ) {
      const { payload } = action;
      state.enabledAreaManagerOptions = payload;
    },
    addEnabledAreaManagerOption(state, action: PayloadAction<Option>) {
      state.enabledAreaManagerOptions.push(action.payload);
    },
  },
});

export * from './type';
export * from './selector';
